import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import styled from "styled-components";
import { RoutePaths } from "src/Routes";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios } from "src/utils/Axios";
const { Title, Text } = Typography;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${(props) => props.theme.color_primary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const ForgotPassword = () => {
    const { t } = useTranslation();
    const [resetSuccess, setResetSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    usePageTitle(t("common.page_title.forgotPassword"));
    const onFinish = ({ email }) => {
        setLoading(true);
        Axios.post("/partner/account/reset", {
            email,
        })
            .then(() => {
            setResetSuccess(true);
        }, (error) => {
            void Axios.error(error, t("common.errors.forgotPassword"));
        })
            .finally(() => setLoading(false));
    };
    if (resetSuccess) {
        return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24, align: "center" }, { children: [_jsx(CheckCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("authentication.login.passwordResetEmailSentTitle") })), _jsx(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: t("authentication.login.passwordResetEmailSentSubtitle") })), _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", style: { marginTop: 48 }, onClick: () => navigate(RoutePaths.Login) }, { children: t("authentication.login.goToLogin") }))] })));
    }
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24 }, { children: [_jsxs("div", { children: [_jsx(Title, Object.assign({ level: 3 }, { children: t("authentication.login.forgotPassword") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("authentication.login.forgotPasswordSubtitle") }))] }), _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish }, { children: [_jsx(Form.Item, Object.assign({ name: "email", label: t("dashboard.company_representatives.emailAddress"), rules: [
                            {
                                required: true,
                                message: t("common.errors.fieldRequired"),
                            },
                        ] }, { children: _jsx(Input, { type: "email", placeholder: t("dashboard.company_representatives.emailExample") }) })), _jsx(Row, Object.assign({ style: { marginTop: 48 } }, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", "data-cy": "reset-password-button", loading: loading }, { children: t("settings.resetPassword") })) })) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: 16 } }, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsxs(Text, Object.assign({ style: { textAlign: "center", marginTop: 16 } }, { children: [t("authentication.login.haveYourPassword"), _jsx(Link, Object.assign({ to: RoutePaths.Login, style: { marginLeft: "4px" } }, { children: t("authentication.login.goToLogin") }))] })) })) }))] }))] })));
};
export default ForgotPassword;
