import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
var FieldUiType;
(function (FieldUiType) {
    FieldUiType[FieldUiType["TextArea"] = 0] = "TextArea";
    FieldUiType[FieldUiType["RadioButtons"] = 1] = "RadioButtons";
    FieldUiType[FieldUiType["NumberInput"] = 2] = "NumberInput";
    FieldUiType[FieldUiType["Currency"] = 3] = "Currency";
    FieldUiType[FieldUiType["Percent"] = 4] = "Percent";
    FieldUiType[FieldUiType["Select"] = 5] = "Select";
})(FieldUiType || (FieldUiType = {}));
const getFieldType = (config) => {
    if (config.ui.is_radio_button) {
        return FieldUiType.RadioButtons;
    }
    if (config.ui.is_select) {
        return FieldUiType.Select;
    }
    if (config.ui.is_multiline) {
        return FieldUiType.TextArea;
    }
    if (config.ui.is_currency) {
        return FieldUiType.Currency;
    }
    if (config.ui.is_percent) {
        return FieldUiType.Percent;
    }
    if (config.type === "decimal" || config.type === "integer") {
        return FieldUiType.NumberInput;
    }
};
const CustomField = ({ config, translations }) => {
    const { t } = useTranslation();
    const { currency, decimalToPercent, localizedNumber } = useFormatter();
    const { defaultCurrency } = useSelector((state) => state.configState);
    const fieldType = useMemo(() => getFieldType(config), [config]);
    // { precision: null } causes issues with input on blur
    const precision = config.ui.precision !== null ? config.ui.precision : undefined;
    const getValidationRules = useMemo(() => {
        const rules = [];
        if (config.rules.is_required) {
            rules.push({
                required: true,
                message: t("common.errors.fieldRequired"),
            });
        }
        if (config.rules.min) {
            switch (config.type) {
                case "string":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return isValidNumericValue(config.rules.min) && value.length >= config.rules.min
                                ? Promise.resolve()
                                : Promise.reject(t("common.fieldMinLength", { min: config.rules.min }));
                        },
                    });
                    break;
                case "integer":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return isValidNumericValue(config.rules.min) && value >= config.rules.min
                                ? Promise.resolve()
                                : Promise.reject(t("common.errors.fieldMinValue", {
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    min: localizedNumber({ value: config.rules.min }),
                                }));
                        },
                    });
                    break;
                case "decimal":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            if (config.ui.is_percent) {
                                const min = isValidNumericValue(config.rules.min) &&
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    Number(decimalToPercent(config.rules.min, config.ui.precision, false, true));
                                return isValidNumericValue(min) && value >= min
                                    ? Promise.resolve()
                                    : Promise.reject(t("common.errors.fieldMinValue", {
                                        min: decimalToPercent(
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        config.rules.min, config.ui.precision, config.ui.is_percent),
                                    }));
                            }
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return isValidNumericValue(config.rules.min) && value >= config.rules.min
                                ? Promise.resolve()
                                : Promise.reject(t("common.errors.fieldMinValue", {
                                    min: localizedNumber({
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        value: config.rules.min,
                                        decimalPlaces: config.ui.precision,
                                    }),
                                }));
                        },
                    });
                    break;
            }
        }
        if (config.rules.max) {
            switch (config.type) {
                case "string":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            return config.rules.max && value.length <= config.rules.max
                                ? Promise.resolve()
                                : Promise.reject(t("common.fieldMaxLength", { max: config.rules.max }));
                        },
                    });
                    break;
                case "integer":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return isValidNumericValue(config.rules.max) && value <= config.rules.max
                                ? Promise.resolve()
                                : Promise.reject(t("common.errors.fieldMaxValue", {
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    max: localizedNumber({ value: config.rules.max }),
                                }));
                        },
                    });
                    break;
                case "decimal":
                    rules.push({
                        validator: (_, value) => {
                            if (!isValidNumericValue(value)) {
                                return Promise.resolve();
                            }
                            if (config.ui.is_percent) {
                                const max = isValidNumericValue(config.rules.max) &&
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    Number(decimalToPercent(config.rules.max, config.ui.precision, false, true));
                                return max && value <= max
                                    ? Promise.resolve()
                                    : Promise.reject(t("common.errors.fieldMaxValue", {
                                        max: decimalToPercent(
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        config.rules.max, config.ui.precision, config.ui.is_percent),
                                    }));
                            }
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return isValidNumericValue(config.rules.max) && value <= config.rules.max
                                ? Promise.resolve()
                                : Promise.reject(t("common.errors.fieldMaxValue", {
                                    max: localizedNumber({
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        value: config.rules.max,
                                        decimalPlaces: config.ui.precision,
                                    }),
                                }));
                        },
                    });
                    break;
            }
        }
        return rules;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);
    const formItemProps = useMemo(() => ({
        name: config.name,
        label: translations.label,
        rules: getValidationRules,
        validateFirst: true,
        initialValue: config.ui.default_value,
    }), [translations, config, getValidationRules]);
    const renderRadioButtons = useCallback(() => {
        var _a, _b;
        if (config.type === "string" && ((_a = config.ui.possible_values) === null || _a === void 0 ? void 0 : _a.length)) {
            return config.ui.possible_values.map((v) => (_jsx(Radio, Object.assign({ value: v }, { children: config.ui.is_currency && defaultCurrency
                    ? currency(v, {
                        currency: defaultCurrency,
                        showFractions: v.toString().includes("."),
                    })
                    : v }), v)));
        }
        if (config.ui.is_currency && defaultCurrency && ((_b = config.ui.possible_values) === null || _b === void 0 ? void 0 : _b.length)) {
            return (_jsx(_Fragment, { children: config.ui.possible_values.map((v) => (_jsx(Radio, Object.assign({ value: v }, { children: currency(v, {
                        currency: defaultCurrency,
                        showFractions: false,
                    }) }), v))) }));
        }
        if (config.type === "boolean") {
            return (_jsxs(_Fragment, { children: [_jsx(Radio, Object.assign({ value: true }, { children: t("common.yes") })), _jsx(Radio, Object.assign({ value: false }, { children: t("common.no") }))] }));
        }
    }, [config, defaultCurrency, t, currency]);
    switch (fieldType) {
        case FieldUiType.RadioButtons:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { children: _jsx(Radio.Group, { children: renderRadioButtons() }) })));
        case FieldUiType.TextArea:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { children: _jsx(TextArea, { showCount: true, maxLength: Number(config.rules.max), rows: config.ui.rows || 5, placeholder: translations.placeholder }) })));
        case FieldUiType.NumberInput:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { children: _jsx(NumberInputLocalized, { controls: false, style: { width: "100%" }, placeholder: translations.placeholder, addonAfter: translations.addonAfter, precision: precision }) })));
        case FieldUiType.Percent:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { initialValue: config.ui.default_value &&
                    decimalToPercent(config.ui.default_value, config.ui.precision, false, true) }, { children: _jsx(PercentInput, { style: { width: "100%" }, placeholder: translations.placeholder, addonAfter: translations.addonAfter, precision: precision }) })));
        case FieldUiType.Currency:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { children: _jsx(CurrencyInput, { style: { width: "100%" }, placeholder: translations.placeholder, addonAfter: translations.addonAfter, precision: precision, currency: defaultCurrency }) })));
        case FieldUiType.Select:
            return (_jsx(Form.Item, Object.assign({}, formItemProps, { children: _jsx(Select, { options: config.ui.possible_values.map((v) => ({
                        value: v,
                        label: config.ui.is_percent ? v * 100 : v,
                    })) }) })));
        default:
            return null;
    }
};
export default CustomField;
