import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal as AntdModal, Space, Typography } from "antd";
import styled from "styled-components";
const Modal = styled(AntdModal) `
	.ant-modal-body {
		padding: 24px 32px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 24px 32px;
	}
`;
const SubmitToInternalReviewModal = ({ modalProps }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, Object.assign({ okText: t("common.button_label.confirmApplication"), destroyOnClose: true, closable: false, width: 400 }, modalProps, { children: _jsxs(Space, Object.assign({ size: 16, align: "start" }, { children: [_jsx(QuestionCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("common.messages.reviewedAllDetails") })), _jsx(Typography.Text, { children: t("common.messages.beforeSubmitConfirmDetails") })] })] })) })));
};
const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined) `
	color: ${(props) => props.theme.color_primary};
	margin-top: 2px;
	font-size: 22px;
`;
export default SubmitToInternalReviewModal;
