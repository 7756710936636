import { combineReducers, createStore } from "redux";
import ConfigReducer from "src/store/reducers/configReducer";
import userReducer from "src/store/reducers/userReducer";
const rootReducer = combineReducers({
    userState: userReducer,
    configState: ConfigReducer,
});
let store;
if (process.env.NODE_ENV !== "production") {
    // conditional imports are restricted at typscript configuration
    // eslint-disable-next-line
    const { composeWithDevToolsDevelopmentOnly } = require("@redux-devtools/extension");
    // eslint-disable-next-line
    store = createStore(rootReducer, composeWithDevToolsDevelopmentOnly());
}
else {
    store = createStore(rootReducer);
}
export default store;
