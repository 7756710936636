import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Card, Col, Form, Modal, Row, Spin, message } from "antd";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import PercentInput from "@ui/form/inputs/percent-input/PercentInput";
import { Axios } from "src/utils/Axios";
const TotalCard = styled(Card) `
	min-height: 130px;
	display: flex;
	align-items: center;
`;
const TotalCardValue = styled(Text) `
	font-size: 16px;
	height: 90px;
`;
var PricingField;
(function (PricingField) {
    PricingField["base_interest_rate"] = "base_interest_rate";
    PricingField["external_interest_rate"] = "external_interest_rate";
    PricingField["base_transaction_fee"] = "base_transaction_fee";
    PricingField["external_transaction_fee"] = "external_transaction_fee";
})(PricingField || (PricingField = {}));
const PricingModal = ({ baseInterestRate, externalInterestRate, baseTransactionFee, externalTransactionFee, currency, modalProps, onSend, }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { application_id } = useParams();
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [precalculatedPricing, setPrecalculatedPricing] = useState();
    const { currency: formatCurrency, decimalToPercent, percentToDecimal } = useFormatter();
    const [showTotalValues, setShowTotalValues] = useState({
        total_interest_rate: false,
        total_transaction_fee: false,
        monthly_payment: false,
    });
    const initialValues = useMemo(() => ({
        base_interest_rate: decimalToPercent(baseInterestRate),
        external_interest_rate: isValidNumericValue(externalInterestRate)
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                decimalToPercent(externalInterestRate)
            : "",
        base_transaction_fee: baseTransactionFee,
        external_transaction_fee: isValidNumericValue(externalInterestRate)
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                externalTransactionFee.toString()
            : "",
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInterestRate, externalInterestRate, baseTransactionFee, externalTransactionFee]);
    const getTotalValuesVisibility = () => {
        const interestRateShown = isValidNumericValue(form.getFieldValue(PricingField.external_interest_rate)) &&
            !form.getFieldError(PricingField.external_interest_rate).length;
        setShowTotalValues({
            total_interest_rate: interestRateShown,
            total_transaction_fee: isValidNumericValue(form.getFieldValue(PricingField.external_transaction_fee)) &&
                !form.getFieldError(PricingField.external_transaction_fee).length,
            monthly_payment: interestRateShown,
        });
    };
    const getPrecalculatedPricing = (externalInterestRate, externalTransactionFee) => {
        if (!isValidNumericValue(externalInterestRate) &&
            !isValidNumericValue(externalTransactionFee)) {
            return getTotalValuesVisibility();
        }
        setLoading(true);
        void Axios.patch(`/partner/applications/${application_id}/pricing_precalculate`, Object.assign(Object.assign({}, (isValidNumericValue(externalInterestRate) && {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            external_interest_rate: Number(percentToDecimal(externalInterestRate)),
        })), (isValidNumericValue(externalTransactionFee) && {
            external_transaction_fee: Number(externalTransactionFee),
        })))
            .then((res) => setPrecalculatedPricing(res.data))
            .catch((err) => void Axios.error(err, t("common.errors.errorGettingMonthlyPayment")))
            .finally(() => {
            setLoading(false);
            getTotalValuesVisibility();
        });
    };
    const precalculate = ({ external_interest_rate, external_transaction_fee, }) => {
        if (!form.getFieldError(PricingField.external_interest_rate).length ||
            !form.getFieldError(PricingField.external_transaction_fee).length) {
            getPrecalculatedPricing(external_interest_rate, external_transaction_fee);
        }
        else {
            getTotalValuesVisibility();
        }
    };
    const handleChange = (_, fields) => {
        precalculate({
            [PricingField.external_interest_rate]: fields[1].value,
            [PricingField.external_transaction_fee]: fields[3].value,
        });
    };
    const handleSubmit = (values) => {
        setSending(true);
        Axios.patch(`/partner/applications/${application_id}/pricing`, Object.assign(Object.assign({}, (isValidNumericValue(values.external_interest_rate) && {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            external_interest_rate: Number(percentToDecimal(values.external_interest_rate)),
        })), { external_transaction_fee: Number(values.external_transaction_fee) }))
            .then(() => {
            onSend();
            void message.success(t("common.saved"));
        })
            .catch((error) => void Axios.error(error, t("common.errors.sendData")))
            .finally(() => setSending(false));
    };
    const renderTotalCard = (field, value) => {
        return (_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Spin, Object.assign({ size: "small", spinning: loading }, { children: _jsxs(TotalCard, { children: [_jsx("div", Object.assign({ style: { marginBottom: 8 } }, { children: t(`dashboard.loan_information.pricing_modal.${field}`) })), showTotalValues[field] && value ? (_jsx(TotalCardValue, Object.assign({ strong: true }, { children: value }))) : (_jsx(TotalCardValue, Object.assign({ type: "secondary" }, { children: "-" })))] }) })) })));
    };
    useEffect(() => {
        precalculate(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalProps, initialValues]);
    return (_jsxs(Modal, Object.assign({ okText: t("common.button_label.save"), onOk: () => form.submit(), okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { width: 650 }, { children: [_jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: initialValues, onFinish: handleSubmit, onFieldsChange: debounce(handleChange, 500), preserve: false }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("dashboard.loan_information.pricing_modal.title.interest_rate") })), _jsxs(Row, Object.assign({ gutter: 32 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ label: t("dashboard.loan_information.baseInterestRate"), name: PricingField.base_interest_rate }, { children: _jsx(PercentInput, { disabled: true, style: { width: "100%" } }) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ label: t("dashboard.loan_information.interestRateMarkup"), name: PricingField.external_interest_rate, rules: [
                                        {
                                            required: true,
                                            message: t("common.errors.fieldRequired"),
                                        },
                                        {
                                            validator: (_, value) => value > 99
                                                ? Promise.reject(t("common.errors.fieldMaxValue", { max: "99%" }))
                                                : Promise.resolve(),
                                        },
                                    ] }, { children: _jsx(PercentInput, { style: { width: "100%" }, min: 0 }) })) }))] })), _jsx(Title, Object.assign({ level: 5, style: { marginTop: 16 } }, { children: t("dashboard.loan_information.pricing_modal.title.transaction_fee") })), _jsxs(Row, Object.assign({ gutter: 32 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ label: t("dashboard.loan_information.baseTransactionFee"), name: PricingField.base_transaction_fee }, { children: _jsx(CurrencyInput, { disabled: true, style: { width: "100%" }, currency: currency }) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ label: t("dashboard.loan_information.transactionFeeMarkup"), name: PricingField.external_transaction_fee, rules: [
                                        {
                                            required: true,
                                            message: t("common.errors.fieldRequired"),
                                        },
                                    ] }, { children: _jsx(CurrencyInput, { style: { width: "100%" }, currency: currency, min: 0 }) })) }))] }))] })), _jsxs(Row, Object.assign({ gutter: 16, style: { marginTop: 16 } }, { children: [renderTotalCard("total_interest_rate", (precalculatedPricing === null || precalculatedPricing === void 0 ? void 0 : precalculatedPricing.total_interest_rate)
                        ? decimalToPercent(precalculatedPricing.total_interest_rate, undefined, true)
                        : undefined), renderTotalCard("total_transaction_fee", (precalculatedPricing === null || precalculatedPricing === void 0 ? void 0 : precalculatedPricing.total_transaction_fee)
                        ? formatCurrency(precalculatedPricing.total_transaction_fee, { currency })
                        : undefined), renderTotalCard("monthly_payment", (precalculatedPricing === null || precalculatedPricing === void 0 ? void 0 : precalculatedPricing.monthly_payment)
                        ? formatCurrency(precalculatedPricing.monthly_payment, { currency })
                        : undefined)] }))] })));
};
export default PricingModal;
