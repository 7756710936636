import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Button, Typography, message } from "antd";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { DataCardItem } from "@ui/data-display/DataCard";
import { RoutePaths } from "src/Routes";
import { CompanyStatusTag } from "src/components/CompanyStatusTag";
import { Axios } from "src/utils/Axios";
import { pickTimePassed } from "src/utils/pickTimePassed";
import { CompanyInfoContent, CompanyInfoHeader, CompanyInfoTitle, CompanyInfoWrapper, Divider, GridContainer, StyledGridColumn, StyledGridColumnContent, StyledTitle, } from "./StyledCompanySearchSelectedCompany";
const { Link } = Typography;
const isEmptyObject = (obj) => {
    for (const key in obj) {
        if (key !== "title" && obj[key] !== "-") {
            return false;
        }
    }
    return true;
};
const dateSettings = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
};
export const CompanySearchSelectedCompany = ({ company, }) => {
    const { t } = useTranslation();
    const { date } = useFormatter();
    const navigate = useNavigate();
    const companyData = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        const dataObjects = [
            {
                title: t("company.info-title.company-data"),
                company_name: company.company_name || "-",
                company_register: `${((_a = company === null || company === void 0 ? void 0 : company.company_register) === null || _a === void 0 ? void 0 : _a.city) || ""} ${((_b = company === null || company === void 0 ? void 0 : company.company_register) === null || _b === void 0 ? void 0 : _b.id) || ""}`.trim() || "-",
                company_register_number: (company === null || company === void 0 ? void 0 : company.company_registration_number) || "-",
                company_status: (company === null || company === void 0 ? void 0 : company.company_status) ? (_jsx(CompanyStatusTag, { status: company === null || company === void 0 ? void 0 : company.company_status })) : ("-"),
            },
            {
                title: t("company.info-title.additional-info"),
                company_type: company.company_type || "-",
                founded: (company === null || company === void 0 ? void 0 : company.foundation_date) ? date(company === null || company === void 0 ? void 0 : company.foundation_date, dateSettings) : "-",
                age: (company === null || company === void 0 ? void 0 : company.foundation_date) ? pickTimePassed(company === null || company === void 0 ? void 0 : company.foundation_date, t) : "-",
                employees: company.number_of_employees || "-",
            },
            {
                title: t("company.info-title.address-info"),
                address: `${(company === null || company === void 0 ? void 0 : company.company_street) || ""} ${(company === null || company === void 0 ? void 0 : company.company_house_number) || ""}, ${(company === null || company === void 0 ? void 0 : company.company_postcode) || ""}, ${(company === null || company === void 0 ? void 0 : company.company_location) || ""}`.trim() || "-",
                country: company.company_country
                    ? t(`common.countries.${company.company_country}`)
                    : "-",
            },
            {
                title: t("company.info-title.external-identifiers"),
                hr_number: (company === null || company === void 0 ? void 0 : company.hr_number) || "-",
                tax_number: (company === null || company === void 0 ? void 0 : company.tax_id_number) || "-",
                client_id: company.company_id || "-",
            },
            {
                title: t("company.info-title.contact-info"),
                telephone: ((_c = company === null || company === void 0 ? void 0 : company.contact_info) === null || _c === void 0 ? void 0 : _c.telephone) || "-",
                fax: ((_d = company === null || company === void 0 ? void 0 : company.contact_info) === null || _d === void 0 ? void 0 : _d.fax) || "-",
                website: ((_e = company === null || company === void 0 ? void 0 : company.contact_info) === null || _e === void 0 ? void 0 : _e.website) ? (_jsx(Link, Object.assign({ href: company.contact_info.website, target: "_blank", rel: "noopener noreferrer" }, { children: (_f = company === null || company === void 0 ? void 0 : company.contact_info) === null || _f === void 0 ? void 0 : _f.website }))) : ("-"),
                email: ((_g = company === null || company === void 0 ? void 0 : company.contact_info) === null || _g === void 0 ? void 0 : _g.email) ? (_jsx(Link, Object.assign({ href: `mailto:${company.contact_info.email}` }, { children: company.contact_info.email }))) : ("-"),
            },
        ];
        return dataObjects.filter((obj) => !isEmptyObject(obj));
    }, [company, t, date]);
    const isEven = companyData.length % 2 === 0;
    const handleAddToPortfolio = () => {
        void Axios.post("/partner/portfolio", {
            north_data_id: company.north_data_id,
        })
            .then(({ data }) => {
            void message.success(t("company.info.add-portfolio.success-msg"));
            navigate(generatePath(RoutePaths.CompanyPortfolioOverview, {
                companyId: data.partner_portfolio_item_id,
            }));
        })
            .catch((error) => void Axios.error(error));
    };
    return (_jsxs(CompanyInfoWrapper, { children: [_jsxs(CompanyInfoHeader, { children: [_jsx(StyledTitle, Object.assign({ level: 4, style: { margin: 0 } }, { children: company.company_name })), _jsx(Button, Object.assign({ ghost: true, type: "primary", onClick: handleAddToPortfolio }, { children: t("company.info.add-portfolio") }))] }), _jsx(Divider, {}), _jsx(CompanyInfoContent, { children: _jsx(GridContainer, { children: companyData.map((obj, index) => (_jsx(StyledGridColumn, Object.assign({ addDivider: (isEven && index < companyData.length - 2) ||
                            (!isEven && index < companyData.length - 1) }, { children: _jsxs(StyledGridColumnContent, { children: [_jsx(CompanyInfoTitle, { children: obj.title }), Object.entries(obj).map(([key, value], subIndex) => {
                                    if (key !== "title") {
                                        return (_jsx(DataCardItem, { label: t(`company.info.${key}`), value: value }, subIndex));
                                    }
                                })] }) }), index))) }) }), _jsx(Divider, {})] }));
};
