import { message } from "antd";
import { AxiosSingleton } from "@teylor-tools/utils/constructors/AxiosConstructor";
import store from "src/store/Store";
import { UserActions } from "src/store/actions/userActions";
export let Axios;
export const initAxios = (t) => {
    const beErrorString = (code) => t(`common.be_errors.${code}`);
    let sessionExpiredShown = false;
    Axios = AxiosSingleton.Instance({
        apiPath: env.API_URL,
        t,
        message: (text, duration) => {
            void message.error(text, duration);
        },
        // had to pass it here to keep typescript types inside of the project scope
        beErrorString,
    });
    Axios.axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        var _a;
        if (!error) {
            return Promise.reject();
        }
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            const responseURL = error["request"].responseURL;
            const isUnauthedEndpoint = [
                "/login",
                "/signup",
                "/partner/account/register",
                "/partner/account/recover",
            ].some((path) => responseURL.includes(path));
            if (!isUnauthedEndpoint) {
                store.dispatch({ type: UserActions.LOGOUT });
                if (!sessionExpiredShown) {
                    sessionExpiredShown = true;
                    void message
                        .error(t("common.errors.loggedOut"))
                        .then(() => (sessionExpiredShown = false));
                }
            }
        }
        return Promise.reject(error);
    });
};
