export const mockScores = [
    {
        company_score_id: "csc_2ULpJsoR2pQHMm6hAMK8aMysLwn",
        company_id: "com_2UKk7CbKqEOYNL0rsdt7ST0G3Ak",
        scoring_config_id: "scfg_2SW1zdVJdwv7s3D6yro6W1EOfsQ",
        scoring_config_name: "TDF 1 - Core",
        benchmark_result: [
            {
                period_start: 2022,
                period_end: 2023,
                benchmarking_scores: {
                    additional_loan: {
                        value: 1,
                        mark: "B-",
                    },
                    efficiency: {
                        value: -1,
                        mark: "C-",
                    },
                    leverage: {
                        value: 1,
                        mark: "B+",
                    },
                    liquidity: {
                        value: -1,
                        mark: "C-",
                    },
                    profitability: {
                        value: -4,
                        mark: "C-",
                    },
                    solvency: {
                        value: 2,
                        mark: "B+",
                    },
                },
                total_score: -12,
                mark: "C+",
                warnings: [
                    {
                        type: "red_flag",
                        message: "period 2022 - 2023: low new Loan Performance: current ratio < 1",
                        code: "current_ratio_additional_loan_kpis_1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2022 - 2023: liquidity: current ratio this period < 1",
                        code: "current_ratio_liquidity_kpis_1_less_than_1",
                    },
                    {
                        type: "warning",
                        message: "period 2022 - 2023: profitability: gross profit margin < 3%",
                        code: "gross_profit_margin_margin_kpis_1_less_than_0.03",
                    },
                    {
                        type: "warning",
                        message: "period 2022 - 2023: low solvency: equity ratio < 10%",
                        code: "equity_ratio_solvency_kpis_1_less_than_0.1",
                    },
                ],
            },
            {
                period_start: 2021,
                period_end: 2022,
                benchmarking_scores: {
                    additional_loan: {
                        value: 4,
                        mark: "B",
                    },
                    efficiency: {
                        value: 0,
                        mark: "B-",
                    },
                    future_performance: {
                        value: 0,
                        mark: "B-",
                    },
                    leverage: {
                        value: 5,
                        mark: "A-",
                    },
                    liquidity: {
                        value: -3,
                        mark: "C-",
                    },
                    profitability: {
                        value: -9,
                        mark: "D",
                    },
                    solvency: {
                        value: 2,
                        mark: "B",
                    },
                },
                total_score: 0,
                mark: "B-",
                warnings: [
                    {
                        type: "warning",
                        message: "period 2021 - 2022: low solvency: equity ratio < 10%",
                        code: "equity_ratio_solvency_kpis_0_less_than_0.1",
                    },
                    {
                        type: "warning",
                        message: "period 2021 - 2022: solvency: total assets < 1,000,000",
                        code: "total_assets_financial_summary_0_less_than_1000000",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: liquidity: current ratio this period < 1",
                        code: "current_ratio_liquidity_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: liquidity: current ratio last period < 1",
                        code: "current_ratio_liquidity_kpis_-1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low cash flow for loan affordability: DSCR < 1",
                        code: "debt_service_coverage_ratio_leverage_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                        code: "debt_service_coverage_ratio_future_performance_kpis_1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                        code: "debt_service_coverage_ratio_future_performance_kpis_3_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low new Loan Performance: current ratio < 1",
                        code: "current_ratio_additional_loan_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low new Loan Performance: debtServiceCoverageRatio < 1",
                        code: "debt_service_coverage_ratio_additional_loan_kpis_0_less_than_1",
                    },
                ],
            },
        ],
        final_score: 786,
        final_mark: "B+",
        warnings: [
            {
                type: "red_flag",
                message: "period 2022 - 2023: low new Loan Performance: current ratio < 1",
                code: "current_ratio_additional_loan_kpis_1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2022 - 2023: liquidity: current ratio this period < 1",
                code: "current_ratio_liquidity_kpis_1_less_than_1",
            },
            {
                type: "warning",
                message: "period 2022 - 2023: profitability: gross profit margin < 3%",
                code: "gross_profit_margin_margin_kpis_1_less_than_0.03",
            },
            {
                type: "warning",
                message: "period 2022 - 2023: low solvency: equity ratio < 10%",
                code: "equity_ratio_solvency_kpis_1_less_than_0.1",
            },
            {
                type: "warning",
                message: "period 2021 - 2022: low solvency: equity ratio < 10%",
                code: "equity_ratio_solvency_kpis_0_less_than_0.1",
            },
            {
                type: "warning",
                message: "period 2021 - 2022: solvency: total assets < 1,000,000",
                code: "total_assets_financial_summary_0_less_than_1000000",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: liquidity: current ratio this period < 1",
                code: "current_ratio_liquidity_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: liquidity: current ratio last period < 1",
                code: "current_ratio_liquidity_kpis_-1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low cash flow for loan affordability: DSCR < 1",
                code: "debt_service_coverage_ratio_leverage_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                code: "debt_service_coverage_ratio_future_performance_kpis_1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                code: "debt_service_coverage_ratio_future_performance_kpis_3_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low new Loan Performance: current ratio < 1",
                code: "current_ratio_additional_loan_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low new Loan Performance: debtServiceCoverageRatio < 1",
                code: "debt_service_coverage_ratio_additional_loan_kpis_0_less_than_1",
            },
        ],
        pd_score: 54,
        is_approved: false,
        approved_by_entity_id: "null",
        approved_by_entity_type: "null",
        approved_by_entity_name: "null",
        approved_at: "null",
        loan_amount: "null",
        loan_duration: 5,
        created_by_entity_id: "bou_2D4BhVEOZ5B4KkQ9JvntgemBQgV",
        created_by_entity_type: "bouser",
        created_by_entity_name: "Celina Wierzbicka Celina Wierzbicka",
        created_at: "2023-08-22T18:24:06.158775Z",
        financial_data_ids: [
            "cfd_2ULpGrtWNmpfs3h2vRUhgs00TeP",
            "cfd_2ULpGxIWoGHlKOz6rSuOcmWzDHv",
            "cfd_2ULpGxDHN1MCRoxjQqsJXVRJrK5",
        ],
    },
    {
        company_score_id: "csc_2ULpIKWtK5OHEe0lekqKzOYqtVn",
        company_id: "com_2UKk7CbKqEOYNL0rsdt7ST0G3Ak",
        scoring_config_id: "scfg_2SW1zdVJdwv7s3D6yro6W1EOfsQ",
        scoring_config_name: "TDF 1 - Core",
        benchmark_result: [
            {
                period_start: 2022,
                period_end: 2023,
                benchmarking_scores: {
                    additional_loan: {
                        value: 1,
                        mark: "B-",
                    },
                    efficiency: {
                        value: -1,
                        mark: "C-",
                    },
                    leverage: {
                        value: 1,
                        mark: "B+",
                    },
                    liquidity: {
                        value: -1,
                        mark: "C-",
                    },
                    profitability: {
                        value: -4,
                        mark: "C-",
                    },
                    solvency: {
                        value: 2,
                        mark: "B+",
                    },
                },
                total_score: -12,
                mark: "C+",
                warnings: [
                    {
                        type: "red_flag",
                        message: "period 2022 - 2023: low new Loan Performance: current ratio < 1",
                        code: "current_ratio_additional_loan_kpis_1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2022 - 2023: liquidity: current ratio this period < 1",
                        code: "current_ratio_liquidity_kpis_1_less_than_1",
                    },
                    {
                        type: "warning",
                        message: "period 2022 - 2023: profitability: gross profit margin < 3%",
                        code: "gross_profit_margin_margin_kpis_1_less_than_0.03",
                    },
                    {
                        type: "warning",
                        message: "period 2022 - 2023: low solvency: equity ratio < 10%",
                        code: "equity_ratio_solvency_kpis_1_less_than_0.1",
                    },
                ],
            },
            {
                period_start: 2021,
                period_end: 2022,
                benchmarking_scores: {
                    additional_loan: {
                        value: 4,
                        mark: "B",
                    },
                    efficiency: {
                        value: 0,
                        mark: "B-",
                    },
                    future_performance: {
                        value: 0,
                        mark: "B-",
                    },
                    leverage: {
                        value: 5,
                        mark: "A-",
                    },
                    liquidity: {
                        value: -3,
                        mark: "C-",
                    },
                    profitability: {
                        value: -9,
                        mark: "D",
                    },
                    solvency: {
                        value: 2,
                        mark: "B",
                    },
                },
                total_score: 0,
                mark: "B-",
                warnings: [
                    {
                        type: "warning",
                        message: "period 2021 - 2022: low solvency: equity ratio < 10%",
                        code: "equity_ratio_solvency_kpis_0_less_than_0.1",
                    },
                    {
                        type: "warning",
                        message: "period 2021 - 2022: solvency: total assets < 1,000,000",
                        code: "total_assets_financial_summary_0_less_than_1000000",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: liquidity: current ratio this period < 1",
                        code: "current_ratio_liquidity_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: liquidity: current ratio last period < 1",
                        code: "current_ratio_liquidity_kpis_-1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low cash flow for loan affordability: DSCR < 1",
                        code: "debt_service_coverage_ratio_leverage_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                        code: "debt_service_coverage_ratio_future_performance_kpis_1_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                        code: "debt_service_coverage_ratio_future_performance_kpis_3_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low new Loan Performance: current ratio < 1",
                        code: "current_ratio_additional_loan_kpis_0_less_than_1",
                    },
                    {
                        type: "red_flag",
                        message: "period 2021 - 2022: low new Loan Performance: debtServiceCoverageRatio < 1",
                        code: "debt_service_coverage_ratio_additional_loan_kpis_0_less_than_1",
                    },
                ],
            },
        ],
        final_score: 786,
        final_mark: "B+",
        warnings: [
            {
                type: "red_flag",
                message: "period 2022 - 2023: low new Loan Performance: current ratio < 1",
                code: "current_ratio_additional_loan_kpis_1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2022 - 2023: liquidity: current ratio this period < 1",
                code: "current_ratio_liquidity_kpis_1_less_than_1",
            },
            {
                type: "warning",
                message: "period 2022 - 2023: profitability: gross profit margin < 3%",
                code: "gross_profit_margin_margin_kpis_1_less_than_0.03",
            },
            {
                type: "warning",
                message: "period 2022 - 2023: low solvency: equity ratio < 10%",
                code: "equity_ratio_solvency_kpis_1_less_than_0.1",
            },
            {
                type: "warning",
                message: "period 2021 - 2022: low solvency: equity ratio < 10%",
                code: "equity_ratio_solvency_kpis_0_less_than_0.1",
            },
            {
                type: "warning",
                message: "period 2021 - 2022: solvency: total assets < 1,000,000",
                code: "total_assets_financial_summary_0_less_than_1000000",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: liquidity: current ratio this period < 1",
                code: "current_ratio_liquidity_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: liquidity: current ratio last period < 1",
                code: "current_ratio_liquidity_kpis_-1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low cash flow for loan affordability: DSCR < 1",
                code: "debt_service_coverage_ratio_leverage_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                code: "debt_service_coverage_ratio_future_performance_kpis_1_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low future performance: DSCR next year < 1",
                code: "debt_service_coverage_ratio_future_performance_kpis_3_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low new Loan Performance: current ratio < 1",
                code: "current_ratio_additional_loan_kpis_0_less_than_1",
            },
            {
                type: "red_flag",
                message: "period 2021 - 2022: low new Loan Performance: debtServiceCoverageRatio < 1",
                code: "debt_service_coverage_ratio_additional_loan_kpis_0_less_than_1",
            },
        ],
        pd_score: 54,
        is_approved: false,
        approved_by_entity_id: "null",
        approved_by_entity_type: "null",
        approved_by_entity_name: "null",
        approved_at: "null",
        loan_amount: "null",
        loan_duration: 5,
        created_by_entity_id: "bou_2D4BhVEOZ5B4KkQ9JvntgemBQgV",
        created_by_entity_type: "bouser",
        created_by_entity_name: "Celina Wierzbicka",
        created_at: "2023-08-22T18:23:53.750233Z",
        financial_data_ids: [
            "cfd_2ULpGrtWNmpfs3h2vRUhgs00TeP",
            "cfd_2ULpGxIWoGHlKOz6rSuOcmWzDHv",
            "cfd_2ULpGxDHN1MCRoxjQqsJXVRJrK5",
        ],
    },
];
