import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Space, Spin, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { RoutePaths } from "src/Routes";
import DataItem from "src/components/shared-modules/data-item/DataItem";
import { colors } from "src/helpers/variables";
import { Axios } from "src/utils/Axios";
import { getDisplayedStatus } from "src/utils/applicationStatus";
const ViewButton = styled.div `
	text-align: center;
	padding-bottom: 10px;
`;
const EmptyInfo = styled(Space) `
	width: 100%;
	text-align: center;
`;
const IconFileSearch = styled(FileSearchOutlined) `
	font-size: 32px;
	opacity: 0.65;
`;
const InfoCard = styled(Card) `
	.ant-card-head {
		background: ${colors.antd_light_grey};
	}

	.ant-card-head-title {
		padding: 15px 0;
	}
`;
const SpinWrapper = styled.div `
	display: flex;
	justify-content: center;
`;
const ApplicationInfo = ({ appId, currency }) => {
    const navigate = useNavigate();
    const { currency: formatCurrency } = useFormatter();
    const { t, i18n } = useTranslation();
    const [application, setApplication] = useState();
    const [loading, setLoading] = useState(false);
    const getApplicationInfo = () => {
        if (!appId) {
            return;
        }
        setLoading(true);
        Axios.get(`/partner/applications/${appId}`)
            .then((resp) => setApplication(resp.data))
            .catch((error) => void Axios.error(error, t("common.errors.fetchData")))
            .finally(() => setLoading(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getApplicationInfo, [appId]);
    const data = useMemo(() => {
        var _a;
        if (!application) {
            return [];
        }
        const status = getDisplayedStatus(application.status);
        return [
            {
                label: t("dashboard.overview.applicationId"),
                values: [((_a = application.shortApplicationId) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || "-"],
            },
            {
                label: t("dashboard.loan_information.amount"),
                values: [formatCurrency(Number(application.loanSize), { currency })],
            },
            {
                label: t("dashboard.loan_information.loanDuration"),
                values: [`${application.loanDuration} ${t("common.months")}`],
            },
            {
                label: t("dashboard.loan_information.purpose"),
                values: [application.purposeDescription || "-"],
                split: true,
            },
            {
                label: t("dashboard.company_information.company"),
                values: [String(application.companyName)],
            },
            {
                label: t("dashboard.company_information.legalForm"),
                values: [application.companyType || "-"],
            },
            {
                label: t("dashboard.company_information.hrNumber"),
                values: [application.hrNumber || "-"],
            },
            {
                label: t("dashboard.company_information.address"),
                values: [
                    `${application.street} ${application.houseNumber}`,
                    `${application.companyPostCode} ${application.companyLocation}`,
                    application.companyCountry
                        ? getCountryList(i18n.language)[application.companyCountry]
                        : "",
                ],
                split: true,
            },
            {
                label: t("dashboard.overview.status"),
                values: [status ? t(`common.application_status.${status}`) : ""],
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);
    if (loading) {
        return (_jsx(InfoCard, Object.assign({ title: t("dashboard.overview.applicationInformation") }, { children: _jsx(SpinWrapper, { children: _jsx(Spin, {}) }) })));
    }
    return (_jsx(InfoCard, Object.assign({ title: t("dashboard.overview.applicationInformation") }, { children: appId ? (_jsx(Space, Object.assign({ direction: "vertical", style: { width: "100%" } }, { children: data.map((item, index) => {
                const isLast = index === data.length - 1;
                return (_jsxs(Fragment, { children: [_jsx(DataItem, { label: `${item.label}:`, values: item.values, labelColProps: { span: 12 }, labelStyles: { fontWeight: "bold", display: "flex", justifyContent: "end" } }), (item.split || isLast) && _jsx(Divider, { style: { margin: "12px 0" } }), isLast && (_jsx(ViewButton, { children: _jsx(Button, Object.assign({ ghost: true, type: "primary", icon: _jsx(EditOutlined, {}), onClick: () => navigate(`${RoutePaths.ApplicationsBase}/${appId}`) }, { children: t("common.button_label.viewApplication") })) }))] }, `item-${index}`));
            }) }))) : (_jsxs(EmptyInfo, Object.assign({ direction: "vertical" }, { children: [_jsx(IconFileSearch, {}), _jsx(Typography.Text, { children: t("dashboard.overview.selectApplicationToViewInfo") })] }))) })));
};
export default ApplicationInfo;
