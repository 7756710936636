import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Form, Input, Modal } from "antd";
import IbanFormInput from "src/components/shared-modules/form-items/iban-form-input/IbanFormInput";
import { Axios } from "src/utils/Axios";
const BankInfoModal = ({ bankName, iban, modalProps, onSend }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { application_id } = useParams();
    const [sending, setSending] = useState(false);
    useEffect(() => {
        form.setFieldsValue({
            bank_name: bankName,
            iban,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalProps === null || modalProps === void 0 ? void 0 : modalProps.visible, bankName, iban]);
    const handleSubmit = (values) => {
        setSending(true);
        Axios.patch(`/partner/applications/${application_id}/bankinfo`, {
            bankName: values.bank_name,
            iban: values.iban,
        })
            .then(({ data }) => onSend(data))
            .catch((err) => {
            void Axios.error(err, t("common.errors.sendData"));
        })
            .finally(() => setSending(false));
    };
    return (_jsx(Modal, Object.assign({ title: t("dashboard.overview.editBankInfo"), okText: t("common.button_label.save"), onOk: () => form.submit(), okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: handleSubmit }, { children: [_jsx(Form.Item, Object.assign({ label: t("dashboard.overview.bankName"), name: "bank_name", rules: [
                        {
                            required: true,
                            message: t("common.errors.fieldRequired"),
                        },
                    ] }, { children: _jsx(Input, {}) })), _jsx(IbanFormInput, {})] })) })));
};
export default BankInfoModal;
