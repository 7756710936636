import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal as AntdModal, Space, Typography } from "antd";
import styled from "styled-components";
const Modal = styled(AntdModal) `
	.ant-modal-body {
		padding: 24px 32px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 24px 32px 24px 0;
	}
`;
const SubmitToPreCheckModal = ({ representatives, modalProps }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, Object.assign({}, modalProps, { okText: t("common.button_label.submitApplicationConfirm"), cancelText: t("common.button_label.noAdjustRepresentatives"), destroyOnClose: true, closable: false, width: 540 }, { children: _jsxs(Space, Object.assign({ align: "start" }, { children: [_jsx(QuestionCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("common.messages.allRepresentativesAdded") })), _jsxs(Space, Object.assign({ direction: "vertical", size: 20, style: { width: "100%" } }, { children: [_jsx(Typography.Text, { children: t("common.messages.beforeSubmitConfirmSignatory") }), _jsxs(RepresentativesWrapper, { children: [_jsx(Typography.Text, { children: t("dashboard.company_representatives.companyRepresentativesList") }), _jsx("ul", Object.assign({ style: { padding: "10px 20px", fontWeight: "bold" } }, { children: representatives.map((rep) => (_jsxs("li", { children: [rep.first_name, " ", rep.last_name] }, rep.signatory_id))) }))] })] }))] })] })) })));
};
const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined) `
	color: ${(props) => props.theme.color_primary};
	margin-top: 2px;
	font-size: 22px;
`;
const RepresentativesWrapper = styled.div `
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.3);
`;
export default SubmitToPreCheckModal;
