export var UserActions;
(function (UserActions) {
    UserActions["LOGIN"] = "LOGIN";
    UserActions["LOGOUT"] = "LOGOUT";
    UserActions["SET_USER"] = "SET_USER";
})(UserActions || (UserActions = {}));
export const login = () => {
    return {
        type: UserActions.LOGIN,
    };
};
export const logout = (loggedOutByUser = false) => {
    return {
        type: UserActions.LOGOUT,
        payload: { loggedOutByUser },
    };
};
export const setUser = (userInfo) => {
    return {
        type: UserActions.SET_USER,
        payload: { userInfo },
    };
};
