var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tabs as AntdTabs, Button, Space, Tooltip, message } from "antd";
import styled from "styled-components";
import { AllBanksLoanPurposes, ApplicationStatuses, PendingActionType, } from "@teylor-tools/Api";
import DocumentsTab from "@ui/modules/DocumentsTab";
import { BackgroundContext } from "src/App";
import AntdAlert from "src/components/Alert";
import Error from "src/components/Error";
import DataItem from "src/components/shared-modules/data-item/DataItem";
import { colors } from "src/helpers/variables";
import { usePageTitle } from "src/hooks/usePageTitle";
import PageHeaderLayout from "src/layouts/PageHeaderLayout/PageHeaderLayout";
import SpinnerLayout from "src/layouts/SpinnerLayout/SpinnerLayout";
import ObjectsTab from "src/pages/application-drilldown/tabs/objects/ObjectsTab";
import { Axios } from "src/utils/Axios";
import { getDisplayedStatus } from "src/utils/applicationStatus";
import RejectModal from "./modals/RejectModal";
import SubmitToInternalReviewModal from "./modals/SubmitToInternalReviewModal";
import SubmitToPreCheckModal from "./modals/SubmitToPreCheckModal";
import SubmitToSignatureModal from "./modals/SubmitToSignatureModal";
import OverviewTab from "./tabs/overview/OverviewTab";
import RepresentativesTab from "./tabs/representatives/RepresentativesTab";
import SignatureRequestsTab from "./tabs/signature-requests/SignatureRequestsTab";
const Alert = styled(AntdAlert) `
	margin-bottom: 24px;
`;
const Wrapper = styled(PageHeaderLayout) `
	width: 100%;
`;
const HeaderInfo = styled(Space) `
	padding: 0 24px 32px;
	width: 100%;
	background: #fff;
`;
const Tabs = styled(AntdTabs) `
	background: ${colors.grey};

	.ant-tabs-nav-wrap {
		background: #fff;
		padding: 0 24px;
	}

	.ant-tabs-content-holder {
		padding: 12px 24px;
		display: flex;
		justify-content: center;
	}

	.ant-tabs-content {
		max-width: 1400px;
	}
`;
var TabPaneKeys;
(function (TabPaneKeys) {
    TabPaneKeys["Overview"] = "overview";
    TabPaneKeys["CompanyRepresentatives"] = "company_representatives";
    TabPaneKeys["Documents"] = "documents";
    TabPaneKeys["SignatureRequests"] = "signature_requests";
    TabPaneKeys["Objects"] = "objects";
})(TabPaneKeys || (TabPaneKeys = {}));
export var CardEditKeys;
(function (CardEditKeys) {
    CardEditKeys["BankInfo"] = "bank_info";
    CardEditKeys["Pricing"] = "pricing";
    CardEditKeys["Tin"] = "tin";
})(CardEditKeys || (CardEditKeys = {}));
const ApplicationDrilldownPage = () => {
    var _a;
    const { t } = useTranslation();
    const { application_id } = useParams();
    const setBackgroundColor = useContext(BackgroundContext);
    const [error, setError] = useState();
    const [application, setApplication] = useState();
    const [representatives, setRepresentatives] = useState([]);
    const [activeTabPane, setActiveTabPane] = useState(TabPaneKeys.Overview);
    const [editKey, setEditKey] = useState();
    const [loading, setLoading] = useState(true);
    const [pendingItems, setPendingItems] = useState([]);
    const [pendingItemsToDisplay, setPendingItemsToDisplay] = useState([]);
    const [sendingApplication, setSendingApplication] = useState(false);
    const [submitToPreCheckModal, setSubmitToPreCheckModal] = useState(false);
    const [submitToInternalReviewModal, setSubmitToInternalReviewModal] = useState(false);
    const [submitToSignatureModal, setSubmitToSignatureModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [nextStatus, setNextStatus] = useState();
    const signatories = representatives.filter(({ requirements }) => (requirements === null || requirements === void 0 ? void 0 : requirements.is_loan_signature_required) || (requirements === null || requirements === void 0 ? void 0 : requirements.is_ubo_signature_required));
    const representativesTins = useMemo(() => representatives.map((rep) => rep.taxid), [representatives]);
    const displayedStatus = (application === null || application === void 0 ? void 0 : application.status) && getDisplayedStatus(application.status);
    usePageTitle((application === null || application === void 0 ? void 0 : application.companyName)
        ? `${application.companyName} - ${t("common.page_title.applicationList")}`
        : `${t("common.page_title.applicationList")}`);
    const showInReviewAlert = useMemo(() => (application === null || application === void 0 ? void 0 : application.status) &&
        [
            ApplicationStatuses.SalesPreCheck,
            ApplicationStatuses.InternalReview,
            ApplicationStatuses.InternalPricing,
            ApplicationStatuses.FinalReview,
            ApplicationStatuses.AwaitingPayout,
            ApplicationStatuses.Pricing,
        ].includes(application.status), [application === null || application === void 0 ? void 0 : application.status]);
    const submitApplication = (reject = false) => {
        if (!application) {
            return message.error("error.generic");
        }
        setSendingApplication(true);
        const newStatus = reject ? ApplicationStatuses.Rejected : nextStatus;
        Axios.patch(`/partner/applications/${application === null || application === void 0 ? void 0 : application.applicationId}/status`, {
            status: newStatus,
        })
            .then(() => {
            void getApplication();
            void getPendingItems();
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setSendingApplication(false));
    };
    const pendingItemExists = (actionType) => pendingItems.some((action) => action.action === actionType);
    const generatePendingItemsToDisplay = () => {
        const pendingItems = [];
        if (pendingItemExists(PendingActionType.BankNameIbanValid)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addBankInfo"),
                buttonText: t("common.button_label.add"),
                onClick: () => setEditKey(CardEditKeys.BankInfo),
            });
        }
        if (pendingItemExists(PendingActionType.CompanyTinSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addTaxIdNumber"),
                buttonText: t("common.button_label.add"),
                onClick: () => setEditKey(CardEditKeys.Tin),
            });
        }
        if (pendingItemExists(PendingActionType.SignatoriesSpecified) ||
            pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addCompanyRepresentatives"),
                buttonText: t("common.button_label.add"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        // do not show SignatoriesMinimumDataCompleted if SignatoriesSpecified exists, they overlap
        if (pendingItemExists(PendingActionType.SignatoriesMinimumDataCompleted) &&
            !pendingItemExists(PendingActionType.SignatoriesSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.complete-representatives-data"),
                buttonText: t("common.button_label.complete"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        if (pendingItemExists(PendingActionType.DocumentAnnualStatementsUploaded) ||
            pendingItemExists(PendingActionType.DocumentBwaSusaUploaded) ||
            pendingItemExists(PendingActionType.DocumentCurrentDebtRegisterUploaded) ||
            pendingItemExists(PendingActionType.AllRequiredDocumentsUploaded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.uploadDocuments"),
                buttonText: t("common.button_label.upload"),
                onClick: () => setActiveTabPane(TabPaneKeys.Documents),
            });
        }
        if (pendingItemExists(PendingActionType.ApplicationExternalPricingSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.setFinalInterestRate"),
                buttonText: t("common.button_label.set"),
                onClick: () => setEditKey(CardEditKeys.Pricing),
            });
        }
        if (pendingItemExists(PendingActionType.FinanceObjectsAdded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addObjects"),
                buttonText: t("common.button_label.add"),
                onClick: () => setActiveTabPane(TabPaneKeys.Objects),
            });
        }
        if (pendingItemExists(PendingActionType.RepresentativesDataCompleted)) {
            pendingItems.push({
                text: t("dashboard.pending_items.complete_representatives_data"),
                buttonText: t("common.button_label.edit"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        setPendingItemsToDisplay(pendingItems);
    };
    const submitBtns = useMemo(() => {
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.Open) {
            return [
                {
                    text: t("common.button_label.submitApplication"),
                    onSubmit: () => setSubmitToPreCheckModal(true),
                },
            ];
        }
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.PreCheck) {
            return [
                ...(application.possible_states.includes(ApplicationStatuses[ApplicationStatuses.Rejected])
                    ? [
                        {
                            text: t("common.button_label.rejectApplication"),
                            danger: true,
                            icon: _jsx(CloseOutlined, {}),
                            onSubmit: () => setRejectModal(true),
                        },
                    ]
                    : []),
                {
                    text: t("common.button_label.confirmApplication"),
                    icon: _jsx(CheckOutlined, {}),
                    onSubmit: () => setSubmitToInternalReviewModal(true),
                },
            ];
        }
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.ExternalPricing) {
            return [
                {
                    text: t("common.button_label.submitPrice"),
                    onSubmit: () => setSubmitToSignatureModal(true),
                },
            ];
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application === null || application === void 0 ? void 0 : application.status]);
    const getRepresentatives = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}/signatories`)
            .then((resp) => {
            setRepresentatives(resp.data.signatories);
        })
            .catch((err) => setError(err));
    });
    const getPendingItems = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}/next_status_validate`).then(({ data }) => {
            setPendingItems(data.pending_actions);
            setNextStatus(data.next_status);
        });
    });
    const getApplication = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}`).then((resp) => {
            setApplication(resp.data);
        });
    });
    useEffect(() => {
        setBackgroundColor(colors.grey);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            yield getApplication();
            yield getRepresentatives();
            yield getPendingItems();
        }))()
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        generatePendingItemsToDisplay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingItems]);
    // @ts-ignore - issue with the type because of conditional rendering
    const tabs = application
        ? [
            {
                key: TabPaneKeys.Overview,
                label: t("dashboard.overview.overview"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(OverviewTab, { application: application, pendingItemsData: pendingItemsToDisplay, editKey: editKey, setEditKey: setEditKey, onUpdate: () => {
                                void getApplication();
                                void getPendingItems();
                            }, representativesTins: representativesTins })] })),
            },
            {
                key: TabPaneKeys.CompanyRepresentatives,
                label: t("dashboard.overview.companyRepresentatives"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(RepresentativesTab, { applicationStatus: application.status, representatives: representatives, onUpdate: () => {
                                void getPendingItems();
                                void getRepresentatives();
                            }, ownershipPercentageExceeded: pendingItemExists(PendingActionType.SignatoriesSharesLe100), noSoleSignatory: pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2), companyTin: application.taxIdNumber || "" })] })),
            },
            {
                key: TabPaneKeys.Documents,
                label: t("dashboard.overview.documents"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(DocumentsTab, { axios: Axios, apiPath: `/partner/applications/${application.applicationId}/documents`, canUpload: [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status), categoriesConfig: application.document_configurations.document_categories || [], onUpdate: () => void getPendingItems(), canChangeClientVisibility: true, translations: {
                                errorGeneric: t("common.errors.generic"),
                                fileUploadFail: t("common.errors.file-upload-fail"),
                                fileUploadSuccess: t("dashboard.documents.file-upload-success"),
                                fileDeleteSuccess: t("dashboard.documents.file-delete-success"),
                                fileDeleteFail: t("common.errors.file-delete-fail"),
                                errorTryAgain: t("common.errors.error-try-again"),
                                errorUploadInProgress: t("common.errors.file-upload-in-progress"),
                                errorFileNotAvailable: t("common.errors.file-not-available"),
                                dragDrop: t("dashboard.documents.drag-drop-below"),
                                uploadBtn: t("common.button_label.upload"),
                                downloadTemplate: t("dashboard.documents.click-here-download-template"),
                                // @ts-ignore dynamic values for categories and types
                                getTranslation: (key) => t(key),
                            } })] })),
            },
            application.status === ApplicationStatuses.Signature
                ? {
                    key: TabPaneKeys.SignatureRequests,
                    label: t("dashboard.overview.signatureRequests"),
                    children: (_jsxs(_Fragment, { children: [showInReviewAlert && (_jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true })), _jsx(SignatureRequestsTab, { signatories: signatories, getRepresentatives: getRepresentatives })] })),
                }
                : {},
            application.allBanksLoanPurpose === AllBanksLoanPurposes.ObjectFinancing
                ? {
                    key: TabPaneKeys.Objects,
                    label: t("dashboard.objects.objects"),
                    children: (_jsxs(_Fragment, { children: [showInReviewAlert && (_jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true })), _jsx(ObjectsTab, { applicationStatus: application.status, applicationId: application.applicationId, onUpdate: () => void getPendingItems(), currency: application.currency })] })),
                }
                : {},
        ]
        : [];
    if (error)
        return _jsx(Error, { error: error });
    return (_jsxs(SpinnerLayout, Object.assign({ loading: loading }, { children: [_jsx("div", { children: !loading && !!application && (_jsxs(Wrapper, Object.assign({ title: t("application_process.applications"), subtitle: application === null || application === void 0 ? void 0 : application.companyName, extra: _jsx(Space, Object.assign({ size: 20 }, { children: submitBtns.map((options, index) => (_jsx(Tooltip, Object.assign({ title: !options.danger && !!pendingItemsToDisplay.length
                                ? t("dashboard.pending_items.applDisabledSubmitPendingItems")
                                : "" }, { children: _jsx(Button, Object.assign({ type: "primary", danger: !!options.danger, "data-cy": "submit-application-button", size: "large", icon: options.icon, disabled: !options.danger && !!pendingItemsToDisplay.length, loading: sendingApplication, onClick: options.onSubmit }, { children: options.text })) }), `submit-btn-${index}`))) })) }, { children: [_jsx(HeaderInfo, { children: _jsxs(Space, Object.assign({ size: 30 }, { children: [_jsx(DataItem, { label: `${t("dashboard.overview.applicationId")}:`, values: [String(application.shortApplicationId).toUpperCase()], labelStyles: { fontWeight: "bold" } }), _jsx(DataItem, { label: `${t("dashboard.overview.status")}:`, values: [
                                            displayedStatus ? t(`common.application_status.${displayedStatus}`) : "",
                                        ], labelStyles: { fontWeight: "bold" } })] })) }), _jsx(Tabs, { items: tabs, activeKey: activeTabPane, onChange: (key) => setActiveTabPane(key) })] }))) }), _jsx(SubmitToPreCheckModal, { representatives: representatives, modalProps: {
                    open: submitToPreCheckModal,
                    onCancel: () => {
                        setSubmitToPreCheckModal(false);
                        setActiveTabPane(TabPaneKeys.CompanyRepresentatives);
                    },
                    onOk: () => {
                        setSubmitToPreCheckModal(false);
                        void submitApplication();
                    },
                } }), _jsx(SubmitToInternalReviewModal, { modalProps: {
                    open: submitToInternalReviewModal,
                    onCancel: () => setSubmitToInternalReviewModal(false),
                    onOk: () => {
                        setSubmitToInternalReviewModal(false);
                        void submitApplication();
                    },
                } }), _jsx(SubmitToSignatureModal, { signatories: signatories, finalInterestRate: (_a = application === null || application === void 0 ? void 0 : application.pricing) === null || _a === void 0 ? void 0 : _a.total_interest_rate, modalProps: {
                    open: submitToSignatureModal,
                    onCancel: () => {
                        setSubmitToSignatureModal(false);
                    },
                    onOk: () => {
                        setSubmitToSignatureModal(false);
                        void submitApplication();
                    },
                } }), _jsx(RejectModal, { modalProps: {
                    open: rejectModal,
                    onCancel: () => setRejectModal(false),
                    onOk: () => {
                        setRejectModal(false);
                        void submitApplication(true);
                    },
                } })] })));
};
export default ApplicationDrilldownPage;
