import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Card as AntdCard, Button, Space, Typography } from "antd";
import styled, { css } from "styled-components";
import CompanyRepresentativeInfo from "./CompanyRepresentativeInfo";
const CardStyles = css `
	padding-left: 92px;
	padding-right: 92px;

	& .ant-card-body {
		padding: 0;
	}

	@media (max-width: 768px) {
		padding: 30px;
	}
`;
const TopCard = styled(AntdCard) `
	${CardStyles};
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
	display: flex;
	flex-direction: column;
`;
const BottomCard = styled(AntdCard) `
	${CardStyles};
	border-top: 0;
	padding-top: 40px;
	padding-bottom: 96px;
`;
const UserIcon = styled(UserOutlined) `
	font-size: 19px;
	color: ${(props) => props.theme.color_primary};
`;
const ButtonWrapper = styled.div `
	margin-top: 24px;
`;
const CompanyRepresentativeEmpty = ({ onClick }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { message: t("common.messages.complete-section-alert"), type: "info", showIcon: true }), _jsx(TopCard, { children: _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(UserIcon, {}), _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("dashboard.company_representatives.noCompanyRepresentatives1") })), _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("dashboard.company_representatives.noCompanyRepresentatives2") })), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ type: "primary", size: "large", icon: _jsx(PlusOutlined, {}), style: { marginTop: 16 }, onClick: onClick, "data-cy": "add-representative-button" }, { children: t("common.button_label.newRepresentative") })) })] })) }), _jsx(BottomCard, { children: _jsx(CompanyRepresentativeInfo, {}) })] }));
};
export default CompanyRepresentativeEmpty;
