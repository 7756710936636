import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, EuroCircleOutlined, FileSearchOutlined, PaperClipOutlined, UserOutlined, } from "@ant-design/icons";
import { FeatureName } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { RoutePaths } from "src/Routes";
import { Axios } from "src/utils/Axios";
const findFeature = (feature, data) => data === null || data === void 0 ? void 0 : data.public_features.find((f) => f.name === feature);
const CompanyPortfolioDetailsMenu = ({ companyId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [config, setConfig] = useState();
    // This is needed only temporary to hide unfinished features
    const getConfig = useCallback(() => {
        return Axios.get("/config").then(({ data }) => {
            setConfig(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, []);
    useEffect(() => {
        void getConfig();
    }, []);
    const menuItems = useMemo(() => {
        return [
            {
                icon: _jsx(AppstoreOutlined, {}),
                label: t("company.menu.overview"),
                key: generatePath(RoutePaths.CompanyPortfolioOverview, { companyId }),
            },
            {
                icon: _jsx(UserOutlined, {}),
                label: t("company.menu.persons"),
                key: generatePath(RoutePaths.CompanyPortfolioPersons, { companyId }),
            },
            ...(findFeature(FeatureName.PartnerSabreCompanyDocuments, config)
                ? [
                    {
                        icon: _jsx(PaperClipOutlined, {}),
                        label: t("company.menu.documents"),
                        key: generatePath(RoutePaths.CompanyPortfolioDocuments, { companyId }),
                    },
                ]
                : []),
            ...(findFeature(FeatureName.PartnerSabreCompanyFinancials, config)
                ? [
                    {
                        icon: _jsx(EuroCircleOutlined, {}),
                        label: t("company.menu.financials"),
                        key: generatePath(RoutePaths.CompanyPortfolioFinancials, { companyId }),
                    },
                ]
                : []),
            ...(findFeature(FeatureName.PartnerSabreCompanyScoring, config)
                ? [
                    {
                        icon: _jsx(FileSearchOutlined, {}),
                        label: t("company.menu.scoring"),
                        key: generatePath(RoutePaths.CompanyPortfolioScoring, { companyId }),
                    },
                ]
                : []),
        ];
    }, [companyId, t]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", onClick: (menuItem) => navigate(menuItem.key), items: menuItems, isCollapsible: true, collapseText: t("menu.collapse") }));
};
export default CompanyPortfolioDetailsMenu;
