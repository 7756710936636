var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import ScoringGrade from "@ui/scoring/scoring-grade/ScoringGrade";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { RoutePaths } from "src/Routes";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
// import { Axios } from "src/utils/Axios";
import { mockScores } from "./mock-data";
const { confirm } = Modal;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
`;
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const CompanyScoringPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { company } = useCompany();
    const [scoringData, setScoringData] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingDeleteId, setLoadingDeleteId] = useState("");
    const getCreditScores = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        //TODO implement when backend is ready
        // await Axios.get<
        // 	Admin.CompaniesScoresDetail.RequestQuery,
        // 	Admin.CompaniesScoresDetail.ResponseBody
        // >(`admin/companies/${company.company_id}/scores`).then(
        // 	({ data }) => setScoringData(data),
        // 	(err) => {
        // 		void Axios.error(err);
        // 	}
        // );
        setScoringData(mockScores);
        yield Promise.resolve();
    }), []);
    useEffect(() => {
        setLoading(true);
        getCreditScores().finally(() => setLoading(false));
    }, [getCreditScores]);
    const handleDeleteScoring = (scoreId) => {
        setLoadingDeleteId(scoreId);
        //TODO implement when backend is ready
        // Axios.delete<
        // 	Admin.CompaniesScoresDelete.RequestQuery,
        // 	Admin.CompaniesScoresDelete.ResponseBody
        // >(`admin/companies/${company.company_id}/scores/${scoreId}`)
        // 	.then(
        // 		() => {
        // 			void getCreditScores();
        // 			void message.success(t("company.scoring.score_delete_success"));
        // 		},
        // 		(err) => {
        // 			void Axios.error(err);
        // 		}
        // 	)
        // 	.finally(() => {
        // 		setLoadingDeleteId("");
        // 	});
    };
    const showDeleteModal = (scoreId) => {
        confirm({
            title: t("company.scoring.are-you-sure"),
            icon: _jsx(ExclamationCircleOutlinedIcon, {}),
            content: t("company.scoring.delete_scoring"),
            okText: t("common.button_label.delete"),
            okButtonProps: { danger: true, loading: !!loadingDeleteId },
            onOk: () => {
                void handleDeleteScoring(scoreId);
            },
        });
    };
    const columns = [
        {
            dateIndex: "scoringDate",
            title: t("company.scoring.scoring_date"),
            width: 120,
            render: ({ scoringDate }) => {
                return _jsx(Typography.Text, { children: dayjs(scoringDate).format(dayMonthYear) });
            },
        },
        {
            dataIndex: "final_mark",
            title: t("company.scoring.score"),
            width: 50,
            render: (final_mark) => {
                return _jsx(ScoringGrade, { score: final_mark });
            },
        },
        {
            dataIndex: "scoring_config_name",
            title: t("company.scoring.scorecard"),
            width: 140,
        },
        {
            dataIndex: "is_approved",
            title: t("company.scoring.status"),
            width: 140,
            render: (is_approved) => {
                return is_approved ? (_jsx(Tag, Object.assign({ color: "green" }, { children: t("company.scoring.status.approved") }))) : (_jsx(Tag, Object.assign({ color: "blue" }, { children: t("company.scoring.status.draft") })));
            },
        },
        {
            dataIndex: "created_by_entity_name",
            title: t("company.scoring.created_by"),
            width: 140,
        },
        {
            title: t("company.scoring.actions"),
            width: 140,
            render: (score) => {
                return (_jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "link", onClick: () => {
                                navigate(generatePath(RoutePaths.CompanyPortfolioScoreDetails, {
                                    companyId: company.company_id,
                                    scoreId: score.company_score_id,
                                }));
                            } }, { children: t("common.view") })), !score.is_approved && (_jsx(Button, Object.assign({ type: "link", danger: true, loading: loadingDeleteId === score.company_score_id, onClick: () => showDeleteModal(score.company_score_id) }, { children: t("common.button_label.delete") })))] }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("company.menu.scoring_history") }), _jsx(Wrapper, { children: _jsx(Row, { children: _jsx(Col, Object.assign({ sm: 24 }, { children: _jsx(Table, { loading: loading, columns: columns, rowKey: "score", dataSource: scoringData, pagination: false }) })) }) })] }));
};
export default CompanyScoringPage;
