const AKF_CUSTOM_FIELD_KEY = "purpose_akf_bike";
export var AkfFieldName;
(function (AkfFieldName) {
    // application
    AkfFieldName["is_public_tender"] = "is_public_tender";
    AkfFieldName["application_comment"] = "application_comment";
    AkfFieldName["employer_rate_subsidy"] = "employer_rate_subsidy";
    AkfFieldName["employer_service_subsidy"] = "employer_service_subsidy";
    AkfFieldName["max_bikes_per_employee"] = "max_bikes_per_employee";
    AkfFieldName["price_limit_bike"] = "price_limit_bike";
    AkfFieldName["number_of_employees"] = "number_of_employees";
    // pricing
    AkfFieldName["leasing_factor"] = "leasing_factor";
    AkfFieldName["service_rate"] = "service_rate";
    AkfFieldName["remaining_value"] = "remaining_value";
    AkfFieldName["insurance_rate"] = "insurance_rate";
})(AkfFieldName || (AkfFieldName = {}));
export const akfFields = [
    AkfFieldName.is_public_tender,
    AkfFieldName.application_comment,
    AkfFieldName.employer_rate_subsidy,
    AkfFieldName.employer_service_subsidy,
    AkfFieldName.max_bikes_per_employee,
    AkfFieldName.price_limit_bike,
    AkfFieldName.number_of_employees,
    AkfFieldName.leasing_factor,
    AkfFieldName.service_rate,
    AkfFieldName.remaining_value,
    AkfFieldName.insurance_rate,
];
export const isAkfApp = (customFields) => {
    var _a, _b;
    return !!(customFields &&
        ((_a = customFields.application) === null || _a === void 0 ? void 0 : _a[AKF_CUSTOM_FIELD_KEY]) &&
        ((_b = customFields.pricing) === null || _b === void 0 ? void 0 : _b[AKF_CUSTOM_FIELD_KEY]));
};
export const getCustomFieldConfig = (customFields, name, fieldGroup) => {
    var _a;
    return (_a = customFields === null || customFields === void 0 ? void 0 : customFields[fieldGroup]) === null || _a === void 0 ? void 0 : _a[AKF_CUSTOM_FIELD_KEY].fields.find((field) => field.name === name);
};
export const createAkfFields = (customFields, t) => {
    const createFieldUiConfig = (fieldName, fieldGroup) => {
        const field = getCustomFieldConfig(customFields, fieldName, fieldGroup);
        if (field) {
            const translationPrefix = `application_process.custom_fields.${AKF_CUSTOM_FIELD_KEY}.`;
            return {
                config: field,
                translations: Object.assign(Object.assign({ 
                    // @ts-ignore
                    label: t(`${translationPrefix}${field.name}`) || "" }, (field.ui.placeholder && {
                    // @ts-ignore
                    placeholder: t(`${translationPrefix}${field.ui.placeholder}`) || "",
                })), (field.ui.addon_after && {
                    // @ts-ignore
                    addonAfter: t(`${translationPrefix}${field.ui.addon_after}`) || "",
                })),
            };
        }
    };
    return {
        leasing_factor: createFieldUiConfig(AkfFieldName.leasing_factor, "pricing"),
        service_rate: createFieldUiConfig(AkfFieldName.service_rate, "pricing"),
        remaining_value: createFieldUiConfig(AkfFieldName.remaining_value, "pricing"),
        insurance_rate: createFieldUiConfig(AkfFieldName.insurance_rate, "pricing"),
        employer_rate_subsidy: createFieldUiConfig(AkfFieldName.employer_rate_subsidy, "application"),
        employer_service_subsidy: createFieldUiConfig(AkfFieldName.employer_service_subsidy, "application"),
        max_bikes_per_employee: createFieldUiConfig(AkfFieldName.max_bikes_per_employee, "application"),
        price_limit_bike: createFieldUiConfig(AkfFieldName.price_limit_bike, "application"),
        number_of_employees: createFieldUiConfig(AkfFieldName.number_of_employees, "application"),
        is_public_tender: createFieldUiConfig(AkfFieldName.is_public_tender, "application"),
        application_comment: createFieldUiConfig(AkfFieldName.application_comment, "application"),
    };
};
