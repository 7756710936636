var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CloseCircleOutlined, DownOutlined, LeftOutlined, WarningOutlined, } from "@ant-design/icons";
import { Button, Dropdown, Layout, Modal, Space, Spin, Typography, message } from "antd";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import { RoutePaths } from "src/Routes";
import { Axios } from "src/utils/Axios";
import CompanyOutlet from "./modules/CompanyOutlet";
import CompanyPortfolioDetailsMenu from "./modules/CompanyPortfolioDetailsMenu";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const WarningOutlinedIcon = styled(WarningOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
	font-size: 48px;
`;
const CompanyPortfolioDetailsLayout = () => {
    const { companyId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadingGlobalCompanyData, setLoadingGlobalCompanyData] = useState(true);
    const [company, setCompany] = useState();
    const [globalCompanyData, setGlobalCompanyData] = useState();
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
    const getGlobalCompanyData = useCallback((north_data_id) => {
        return Axios.get(`/partner/global/company`, {
            north_data_id,
        }).then(({ data }) => {
            setGlobalCompanyData(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, []);
    const getPortfolioCompany = useCallback(() => {
        return Axios.get(`/partner/portfolio/${companyId}`);
    }, [companyId]);
    const getCompany = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const res = yield getPortfolioCompany();
            setCompany(res.data);
            setLoading(false);
            try {
                setLoadingGlobalCompanyData(true);
                yield getGlobalCompanyData(res.data.north_data_id);
                setLoadingGlobalCompanyData(false);
            }
            catch (err) {
                void Axios.error(err);
                setLoadingGlobalCompanyData(false);
            }
        }
        catch (err) {
            void Axios.error(err);
        }
        finally {
            setLoading(false);
        }
    }), [getPortfolioCompany, getGlobalCompanyData]);
    useEffect(() => {
        void getCompany();
    }, [getCompany]);
    const handleRemoveCompany = (companyId) => {
        if (!companyId)
            return;
        Axios.delete(`/partner/portfolio/${companyId}`).then(() => {
            void message.success(t("company.remove_from_portfolio.removed"));
            setIsRemoveModalVisible(false);
            navigate(RoutePaths.CompaniesPortfolio);
        }, (err) => {
            void Axios.error(err);
        });
    };
    const updateCompany = () => {
        // TODO implement when backend is ready
        return Promise.resolve();
    };
    useEffect(() => {
        setLoading(true);
        getCompany().finally(() => {
            setLoading(false);
        });
    }, [getCompany]);
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: company && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                            navigate(RoutePaths.CompaniesPortfolio);
                                        } }), company.company_name] })) })), extra: company && (_jsx(Dropdown, Object.assign({ placement: "bottomRight", trigger: ["click"], menu: {
                                items: [
                                    {
                                        label: t("company.remove_from_portfolio"),
                                        key: "delete",
                                        icon: _jsx(CloseCircleOutlined, {}),
                                        danger: true,
                                        onClick: () => setIsRemoveModalVisible(true),
                                    },
                                ],
                            } }, { children: _jsx(Button, Object.assign({ loading: loading }, { children: _jsxs(Space, { children: [t("company.actions"), _jsx(DownOutlined, {})] }) })) }))) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [company && _jsx(CompanyPortfolioDetailsMenu, { companyId: company.partner_portfolio_item_id }), _jsx(Content, { children: company ? (_jsx(CompanyOutlet, { company: company, updateCompany: updateCompany, globalCompanyData: globalCompanyData, loadingGlobalCompanyData: loadingGlobalCompanyData })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) })] }))] })), isRemoveModalVisible && (_jsx(Modal, Object.assign({ title: t("company.remove_from_portfolio.title"), onOk: () => {
                    handleRemoveCompany(company === null || company === void 0 ? void 0 : company.partner_portfolio_item_id);
                }, onCancel: () => setIsRemoveModalVisible(false), okButtonProps: { danger: true }, okText: t("common.remove"), visible: true }, { children: _jsxs(Space, Object.assign({ direction: "vertical", align: "center", size: "large", style: { padding: "0 64px", textAlign: "center", marginBottom: 16 } }, { children: [_jsx(WarningOutlinedIcon, {}), _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("company.remove_from_portfolio.are_you_sure") })), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_1") }), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_2") }), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_3") })] })) })))] }));
};
export default CompanyPortfolioDetailsLayout;
