import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Row, Typography } from "antd";
import styled from "styled-components";
const DataItem = ({ label, values = [], layout = "row", labelStyles, labelColProps, }) => {
    return (_jsx(_Fragment, { children: values.map((value, index) => (_jsxs(StyledRow, Object.assign({ layout: layout, wrap: false }, { children: [_jsx(Col, Object.assign({}, labelColProps, { children: index === 0 && _jsx(Label, Object.assign({ style: labelStyles }, { children: label })) })), !!value && (_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(Typography.Text, { children: value }) })))] }), `data-item-${index}`))) }));
};
const StyledRow = styled(Row) `
	width: 100%;
	display: flex;
	flex-direction: ${({ layout }) => layout};
`;
const Label = styled(Typography.Text) `
	margin-right: 8px;
`;
export default DataItem;
