import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Popover } from "antd";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import { Axios } from "src/utils/Axios";
const TinNumberModal = ({ tin, onSend, inputLabel, modalProps, representativesTins, }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { application_id } = useParams();
    const [sending, setSending] = useState(false);
    useEffect(() => form.setFieldsValue({ tin }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalProps.open, tin]);
    const handleSubmit = (values) => {
        if (!values.tin) {
            return;
        }
        setSending(true);
        Axios.patch(`/partner/applications/${application_id}`, {
            taxIdNumber: parseTin(values.tin),
        })
            .then(({ data }) => onSend(data))
            .catch((error) => {
            void Axios.error(error, t("common.errors.sendData"));
        })
            .finally(() => setSending(false));
    };
    return (_jsx(Modal, Object.assign({ okText: t("common.button_label.save"), onOk: form.submit, okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { children: _jsx(Form, Object.assign({ form: form, layout: "vertical", onFinish: handleSubmit }, { children: _jsx(TinFormItem, { name: "tin", label: inputLabel || t("application_process.company_details.tin"), rules: [
                    { required: true, message: t("common.errors.fieldRequired") },
                    {
                        validator: (_, value) => validateValueDifferentThan({
                            value: parseTin(value),
                            differentThan: representativesTins,
                            error: t("common.errors.companyTinMustBeDifferentThanPersonalTin"),
                        }),
                    },
                ], inputProps: {
                    suffix: (_jsx(Popover, Object.assign({ content: t("application_process.company_details.tinInfo"), overlayClassName: "global-tooltip-overlay-style", trigger: "click" }, { children: _jsx(InfoCircleOutlined, { className: "info-tooltip" }) }))),
                    placeholder: t("application_process.company_details.placeholder.tin"),
                } }) })) })));
};
export default TinNumberModal;
