import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import ibanLib from "iban";
const IbanFormInput = (props) => {
    const { t } = useTranslation();
    return (_jsx(Form.Item, Object.assign({ label: t("dashboard.overview.iban"), name: "iban", colon: false, labelAlign: "left", rules: [
            {
                required: true,
                message: t("common.errors.fieldRequired"),
            },
            {
                validator: (_, iban = "") => {
                    return !iban || ibanLib.isValid(iban)
                        ? Promise.resolve()
                        : Promise.reject(t("common.errors.ibanInvalid"));
                },
            },
        ] }, props.formItem, { children: _jsx(Input, Object.assign({ type: "text", maxLength: 34 }, props.input)) })));
};
export default IbanFormInput;
