import { ApplicationStatuses } from "@teylor-tools/Api";
export var DisplayedStatus;
(function (DisplayedStatus) {
    DisplayedStatus["NOT_COMPLETED"] = "notCompleted";
    DisplayedStatus["PRE_CHECK"] = "preCheck";
    DisplayedStatus["IN_REVIEW"] = "inReview";
    DisplayedStatus["PRICING"] = "pricing";
    DisplayedStatus["WAITING_FOR_SIGNATURES"] = "waitingForSignatures";
    DisplayedStatus["DECLINED"] = "declined";
    DisplayedStatus["PAID_OUT"] = "paidOut";
})(DisplayedStatus || (DisplayedStatus = {}));
const statusMapping = new Map([
    [ApplicationStatuses.Open, DisplayedStatus.NOT_COMPLETED],
    [ApplicationStatuses.PreCheck, DisplayedStatus.PRE_CHECK],
    [ApplicationStatuses.SalesPreCheck, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.InternalReview, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.InternalPricing, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.Pricing, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.FinalReview, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.Approved, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.AwaitingPayout, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.BankSubmitted, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.OfferAvailable, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.OfferAccepted, DisplayedStatus.IN_REVIEW],
    [ApplicationStatuses.ExternalPricing, DisplayedStatus.PRICING],
    [ApplicationStatuses.Rejected, DisplayedStatus.DECLINED],
    [ApplicationStatuses.Signature, DisplayedStatus.WAITING_FOR_SIGNATURES],
    [ApplicationStatuses.PaidOut, DisplayedStatus.PAID_OUT],
]);
export const getDisplayedStatus = (status) => {
    return statusMapping.get(status);
};
export const getApplicationStatuses = (displayedStatus) => {
    const allStatuses = [...statusMapping.keys()];
    return allStatuses.filter((status) => {
        return statusMapping.get(status) === displayedStatus;
    });
};
