import { jsx as _jsx } from "react/jsx-runtime";
import { cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import styled from "styled-components";
const PasswordInput = styled.div `
	position: relative;
	max-width: 569px;
	width: 100%;

	.ant-input-affix-wrapper {
		input::placeholder {
			font-size: 8px;
			letter-spacing: 9px;
		}

		input.ant-input {
			border-color: transparent !important;
			background: transparent;
			padding: 0;
		}
	}

	// add red border to the input with error
	.ant-row.ant-form-item.ant-form-item-has-error {
		.ant-input,
		.ant-input-affix-wrapper {
			border-color: $error-message;
		}
	}

	.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.ant-input-group {
		display: flex;

		.ant-input-group-addon {
			width: 0;
			padding: 0;
			border: none;
		}
	}

	// highlight placeholder on tooltip opened
	&.highlight-placeholder .ant-input-affix-wrapper input::placeholder {
		color: $dark-placeholder;
	}

	.info-icon {
		position: absolute;
		top: 15px;
		right: -30px;
		cursor: pointer;
		font-size: 16px;
	}

	.input-password {
		&.input-password--highlighted {
			input {
				border-color: $text-dark-blue;
			}
		}
	}
`;
const PasswordFormInput = ({ children, }) => {
    const { t } = useTranslation();
    const modifiedChildren = cloneElement(children, {
        inputProps: Object.assign(Object.assign({}, (children.props.inputProps || {})), { addonAfter: (_jsx(Popover, Object.assign({ content: _jsx("div", Object.assign({ style: { whiteSpace: "pre-line" } }, { children: `${t("authentication.register.passwordInfo1")}\n${t("authentication.register.passwordInfo2")}\n${t("authentication.register.passwordInfo3")}\n${t("authentication.register.passwordInfo4")}\n${t("authentication.register.passwordInfo5")}\n${t("authentication.register.passwordInfo6")}\n${t("authentication.register.passwordInfo7")}\n` })), trigger: "click", overlayClassName: "global-tooltip-overlay-style" }, { children: _jsx(InfoCircleOutlined, { style: { marginLeft: 5 } }) }))) }),
    });
    return _jsx(PasswordInput, { children: modifiedChildren });
};
export default PasswordFormInput;
