import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import styled from "styled-components";
// This component will show a full page loading screen if data doesn't fetch/update instantly.
const SpinnerLayout = ({ loading, children }) => {
    const [showSpinner, setShowSpinner] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 750);
        return () => clearTimeout(timer);
    });
    return (_jsxs(_Fragment, { children: [showSpinner && (_jsx(Wrapper, Object.assign({ "$loading": loading }, { children: _jsx(Spin, {}) }))), children] }));
};
const Wrapper = styled.div `
	background: #fff;
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: ${({ $loading }) => ($loading ? "100000" : "-100000")};
	opacity: ${({ $loading }) => ($loading ? 0.6 : 0)};
	transition: opacity 0.3s linear, z-index 0.3s step-end;
	transition-delay: 0.3s;
`;
export default SpinnerLayout;
