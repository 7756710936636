import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Typography } from "antd";
import styled from "styled-components";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";
import { RoutePaths } from "src/Routes";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios } from "src/utils/Axios";
const { Title, Text } = Typography;
const CheckCircleIcon = styled(CheckCircleOutlined) `
	color: ${(props) => props.theme.color_primary};
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Header = styled.div `
	margin-bottom: 24px;
`;
const ResetPassword = () => {
    const { t } = useTranslation();
    const [resetSuccess, setResetSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();
    const [form] = Form.useForm();
    usePageTitle(t("common.page_title.resetPassword"));
    const onFinish = ({ password }) => {
        setLoading(true);
        Axios.post("/partner/account/recover", {
            password,
            token,
        })
            .then(() => {
            setResetSuccess(true);
        }, (error) => {
            void Axios.error(error, t("common.errors.forgotPassword"));
        })
            .finally(() => setLoading(false));
    };
    if (resetSuccess) {
        return (_jsxs(Space, Object.assign({ direction: "vertical", size: 24, align: "center" }, { children: [_jsx(CheckCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("authentication.login.passwordResetEmailSuccessTitle") })), _jsx(Text, Object.assign({ type: "secondary", style: { textAlign: "center" } }, { children: t("authentication.login.passwordResetEmailSuccessSubtitle") })), _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", style: { marginTop: 48 }, onClick: () => navigate(RoutePaths.Login) }, { children: t("authentication.login.goToLogin") }))] })));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Header, { children: [_jsx(Title, Object.assign({ level: 3 }, { children: t("settings.resetPassword") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("authentication.login.resetPasswordSubtitle") }))] }), _jsxs(Form, Object.assign({ form: form, layout: "vertical", onFinish: onFinish }, { children: [_jsx(PasswordFormItem, { name: "password", label: t("settings.newPassword") }), _jsx(PasswordFormItem, { name: "passwordRepeat", label: t("settings.newPasswordRepeat"), rules: [
                            { required: true, message: t("common.errors.fieldRequired") },
                            {
                                validator: (_, passwordRepeat) => passwordRepeat === form.getFieldValue("password")
                                    ? Promise.resolve()
                                    : Promise.reject(t("common.errors.passwordRepeatInvalid")),
                            },
                        ] }), _jsx(Row, Object.assign({ style: { marginTop: 48 } }, { children: _jsx(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", loading: loading }, { children: t("settings.resetPassword") })) })) }))] }))] }));
};
export default ResetPassword;
