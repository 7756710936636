export var ConfigAction;
(function (ConfigAction) {
    ConfigAction["SET_CONFIG"] = "SET_CONFIG";
    ConfigAction["SET_COUNTRY_LIST"] = "SET_COUNTRY_LIST";
})(ConfigAction || (ConfigAction = {}));
export const setConfig = (payload) => {
    return {
        type: ConfigAction.SET_CONFIG,
        payload,
    };
};
export const setCountryList = ({ preferredCountries, countries, }) => {
    return {
        type: ConfigAction.SET_COUNTRY_LIST,
        payload: {
            preferredCountries,
            countries,
        },
    };
};
