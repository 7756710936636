import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const CompanyFinancialsPage = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("company.menu.financials") }), _jsx(Wrapper, { children: "Financials" })] }));
};
export default CompanyFinancialsPage;
