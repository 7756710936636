import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal as AntdModal, Space, Typography } from "antd";
import styled from "styled-components";
const Modal = styled(AntdModal) `
	.ant-modal-body {
		margin-bottom: 32px;
	}

	.ant-modal-footer {
		border-top: none;
	}
`;
const DeleteRepresentativeModal = ({ name, modalProps }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, Object.assign({ okText: t("common.button_label.delete"), okButtonProps: { danger: true }, destroyOnClose: true, closable: false, width: 400 }, modalProps, { children: _jsxs(Space, Object.assign({ size: 16, align: "start" }, { children: [_jsx(ExclamationCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("common.messages.areYouSure") })), _jsx(Typography.Text, { children: _jsx(Trans, Object.assign({ i18nKey: "dashboard.company_representatives.delete-representative-info" }, { children: { name } })) })] })] })) })));
};
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${(props) => props.theme.colorError};
	margin-top: 2px;
	font-size: 22px;
`;
export default DeleteRepresentativeModal;
