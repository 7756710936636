import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "antd";
import styled from "styled-components";
import { CompanySearchAutocomplete } from "./company-search-autocomplete/CompanySearchAutocomplete";
import { CompanySearchInfo } from "./company-search-info/CompanySearchInfo";
const ContentWrapper = styled.div `
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
`;
const CompanyHeader = styled.div `
	margin-top: 24px;
`;
export const CompanySearchPage = () => {
    const { t } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState();
    return (_jsxs(ContentWrapper, { children: [_jsxs(CompanyHeader, { children: [_jsx(Typography.Title, Object.assign({ level: 4, style: { margin: "0 24px" } }, { children: t("company.page.title") })), _jsx(Divider, {}), _jsx(CompanySearchAutocomplete, { setSelectedCompany: setSelectedCompany })] }), _jsx(CompanySearchInfo, { selectedCompany: selectedCompany })] }));
};
