import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/reset.css";
import "core-js";
import { extend } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";
import AppConfig from "src/AppConfig";
import i18nInit from "src/i18n/i18nInit";
import { TranslationsProvider } from "src/providers/translations-provider/translations";
import store from "src/store/Store";
import Routes from "./Routes";
import "./global.css";
import { initAxios } from "./utils/Axios";
import GlobalStyle from "./utils/globalStyles";
import "./variables.css";
export const BackgroundContext = createContext(() => void {});
i18nInit();
const Setup = () => {
    const [bgColor, setBgColor] = useState("#FFF");
    const [ready, setReady] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        extend(utc);
        extend(timezone);
        extend(customParseFormat);
        initAxios(t);
        setReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BackgroundContext.Provider, Object.assign({ value: setBgColor }, { children: ready && (_jsxs(BrowserRouter, { children: [_jsx(GlobalStyle, {}), _jsx(AppContainer, Object.assign({ bgColor: bgColor }, { children: _jsx(TranslationsProvider, { children: _jsx(AppConfig, { children: _jsx(Routes, {}) }) }) }))] })) })) })));
};
const rootElement = document.getElementById("root");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(_jsx(Setup, {}));
}
const AppContainer = styled.div `
	display: flex;
	overflow: hidden;
	height: 100vh;

	.ant-layout-content {
		background: ${({ bgColor }) => bgColor} !important;
	}
`;
