import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CheckOutlined as AntdCheckOutlined } from "@ant-design/icons/lib/icons";
import { default as AntdTitle } from "antd/es/typography/Title";
import styled from "styled-components";
const CheckOutlined = styled(AntdCheckOutlined) `
	color: ${(props) => props.theme.color_primary};
	margin-right: 8px;
`;
const List = styled.ul `
	color: rgba(0, 0, 0, 0.55);
	padding-left: 50px;
`;
const Title = styled(AntdTitle) `
	margin: 10px 0;
`;
const CompanyRepresentativeInfo = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("dashboard.company_representatives.empty-title") })), _jsxs(Title, Object.assign({ level: 5 }, { children: [_jsx(CheckOutlined, {}), t("dashboard.company_representatives.empty-section-1-title")] })), _jsxs(List, { children: [_jsx("li", { children: t("dashboard.company_representatives.empty-section-1-point-1") }), _jsx("li", { children: t("dashboard.company_representatives.empty-section-1-point-2") })] }), _jsxs(Title, Object.assign({ level: 5 }, { children: [_jsx(CheckOutlined, {}), t("dashboard.company_representatives.empty-section-2-title")] })), _jsx(List, { children: _jsx("li", { children: t("dashboard.company_representatives.empty-section-2-point-1") }) }), _jsxs(Title, Object.assign({ level: 5 }, { children: [_jsx(CheckOutlined, {}), t("dashboard.company_representatives.empty-section-3-title")] })), _jsxs(List, { children: [_jsx("li", { children: t("dashboard.company_representatives.empty-section-3-point-1") }), _jsx("li", { children: t("dashboard.company_representatives.empty-section-3-point-2") })] })] }));
};
export default CompanyRepresentativeInfo;
