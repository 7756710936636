import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, List, Row, Typography } from "antd";
import dayjs from "dayjs";
import styled, { useTheme } from "styled-components";
import { AllBanksLoanPurposes, ApplicationStatuses, EntitlementType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useIndicativeRate } from "@teylor-tools/hooks/useIndicativeRates";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import DataItem from "src/components/shared-modules/data-item/DataItem";
import { colors } from "src/helpers/variables";
import { CardEditKeys, } from "src/pages/application-drilldown/ApplicationDrilldownPage";
import PricingModal from "src/pages/application-drilldown/tabs/overview/modals/PricingModal";
import { AkfFieldName, getCustomFieldConfig } from "src/pages/apply/akf";
import TinNumberModal from "../../modals/TinNumberModal";
import BankInfoModal from "./modals/BankInfoModal";
const OverviewTab = ({ application, pendingItemsData, editKey, setEditKey, onUpdate, representativesTins, }) => {
    var _a, _b, _c, _d, _e;
    const { t, i18n } = useTranslation();
    const { currency, decimalToPercent, localizedNumber } = useFormatter();
    const { indicativeMonthlyRate, indicativeInterestRate } = useIndicativeRate();
    const theme = useTheme();
    const { customFields, entitlements } = useSelector((state) => state.configState);
    const isAkf = application.allBanksLoanPurpose === AllBanksLoanPurposes.PurposeAkfBike;
    const showCustomPricing = (entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) && entitlements.includes(EntitlementType.CustomizedPricing);
    const isIndicative = showCustomPricing
        ? !((_a = application.pricing) === null || _a === void 0 ? void 0 : _a.is_final_interest_rate) &&
            !isValidNumericValue((_b = application.pricing) === null || _b === void 0 ? void 0 : _b.external_interest_rate)
        : !((_c = application.pricing) === null || _c === void 0 ? void 0 : _c.is_final_interest_rate);
    const NotAvailableJsx = useMemo(() => (_jsx(Typography.Text, Object.assign({ type: "secondary", style: { opacity: 0.5 } }, { children: t("common.notAvailable") }))), [t]);
    const monthlyPayment = useMemo(() => {
        if (isIndicative) {
            const rate = indicativeMonthlyRate(application.pricing, application.currency);
            return rate ? `${rate} (${t("common.indicative")})` : NotAvailableJsx;
        }
        if (isValidNumericValue(application.pricing.monthly_payment)) {
            return currency(application.pricing.monthly_payment, { currency: application.currency });
        }
        return NotAvailableJsx;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.pricing, isIndicative]);
    const baseInterestRate = useMemo(() => {
        if (isIndicative) {
            const rate = indicativeInterestRate(application.pricing);
            return rate ? `${rate} (${t("common.indicative")})` : NotAvailableJsx;
        }
        if (isValidNumericValue(application.pricing.interest_rate)) {
            return decimalToPercent(application.pricing.interest_rate, undefined, true);
        }
        return NotAvailableJsx;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.pricing, isIndicative]);
    const interestRate = useMemo(() => {
        if (isIndicative) {
            return `${indicativeInterestRate(application.pricing)} (${t("common.indicative")})`;
        }
        if (isValidNumericValue(application.pricing.total_interest_rate)) {
            return decimalToPercent(application.pricing.total_interest_rate, undefined, true);
        }
        return NotAvailableJsx;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.pricing, isIndicative]);
    const markupLabelStyle = useMemo(() => ({
        color: !isValidNumericValue(application.pricing.external_interest_rate) &&
            application.status === ApplicationStatuses.ExternalPricing
            ? colors.error
            : "initial",
        opacity: !isValidNumericValue(application.pricing.external_interest_rate) &&
            application.status === ApplicationStatuses.ExternalPricing
            ? 1
            : 0.5,
    }), [application.status, application.pricing.external_interest_rate]);
    const customerData = useMemo(() => [
        {
            label: t("dashboard.overview.clientId"),
            values: [application.companyId],
        },
        {
            label: t("dashboard.overview.founded"),
            values: [
                application.companyFoundationDate
                    ? dayjs(application.companyFoundationDate).format(dayMonthYear)
                    : NotAvailableJsx,
            ],
        },
        {
            label: t("dashboard.overview.name"),
            values: [application.companyName],
        },
        {
            label: t("dashboard.company_information.legalForm"),
            values: [t(`common.company_types.${application.companyType}`)],
        },
        {
            label: t("dashboard.company_information.address"),
            values: [
                `${application.street} ${application.houseNumber}`,
                `${application.companyPostCode} ${application.companyLocation}`,
                application.companyCountry
                    ? getCountryList(i18n.language)[application.companyCountry]
                    : "",
            ],
        },
        {
            label: t("dashboard.company_information.hrNumber"),
            values: [application.hrNumber],
        },
        {
            label: t("dashboard.overview.tinNumber"),
            values: [
                application.taxIdNumber ? (_jsxs(_Fragment, { children: [application.taxIdNumber, [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status) && (_jsx(Button, Object.assign({ type: "link", style: { padding: 0 }, onClick: () => setEditKey(CardEditKeys.Tin) }, { children: _jsx(EditIcon, { color: theme.color_link }) })))] })) : (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Tin) }, { children: t("common.button_label.addTin") }))),
            ],
        },
        {
            label: t("dashboard.loan_information.purpose"),
            values: [application.businessPurpose],
        },
        {
            label: t("dashboard.company_representatives.mainContact"),
            values: [application.user_email || "-"],
        },
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    const loanDataCustomPricing = useMemo(() => {
        var _a, _b, _c;
        return [
            { type: "divider" },
            {
                label: t("dashboard.loan_information.baseInterestRate"),
                values: [baseInterestRate],
            },
            {
                label: t("dashboard.loan_information.interestRateMarkup"),
                values: [
                    isValidNumericValue((_a = application.pricing) === null || _a === void 0 ? void 0 : _a.external_interest_rate) ? (_jsxs(_Fragment, { children: [decimalToPercent(application.pricing.external_interest_rate, undefined, true), application.status === ApplicationStatuses.ExternalPricing && (_jsx(EditIcon, { onClick: () => setEditKey(CardEditKeys.Pricing) }))] })) : application.status === ApplicationStatuses.ExternalPricing ? (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Pricing) }, { children: _jsx(Typography.Text, Object.assign({ underline: true, style: { color: colors.teylor_light_blue } }, { children: t("common.button_label.setInterestRateMarkup") })) }))) : (NotAvailableJsx),
                ],
                labelStyles: markupLabelStyle,
            },
            {
                label: t("dashboard.loan_information.finalInterestRate"),
                values: [
                    isValidNumericValue(application.pricing.external_interest_rate) &&
                        isValidNumericValue((_b = application.pricing) === null || _b === void 0 ? void 0 : _b.total_interest_rate)
                        ? decimalToPercent(application.pricing.total_interest_rate, undefined, true)
                        : NotAvailableJsx,
                ],
            },
            { type: "divider" },
            {
                label: t("dashboard.loan_information.baseTransactionFee"),
                values: [
                    isValidNumericValue(application.pricing.transaction_fee)
                        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            currency(application.pricing.transaction_fee, {
                                currency: application.currency,
                            })
                        : NotAvailableJsx,
                ],
            },
            {
                label: t("dashboard.loan_information.transactionFeeMarkup"),
                values: [
                    isValidNumericValue((_c = application.pricing) === null || _c === void 0 ? void 0 : _c.external_transaction_fee) ? (_jsxs(_Fragment, { children: [currency(application.pricing.external_transaction_fee, {
                                currency: application.currency,
                            }), application.status === ApplicationStatuses.ExternalPricing && (_jsx(EditIcon, { onClick: () => setEditKey(CardEditKeys.Pricing) }))] })) : application.status === ApplicationStatuses.ExternalPricing ? (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Pricing) }, { children: _jsx(Typography.Text, Object.assign({ underline: true, style: { color: colors.teylor_light_blue } }, { children: t("common.button_label.setTransactionFeeMarkup") })) }))) : (NotAvailableJsx),
                ],
                labelStyles: markupLabelStyle,
            },
            {
                label: t("dashboard.loan_information.finalTransactionFee"),
                values: [
                    isValidNumericValue(application.pricing.external_transaction_fee) &&
                        isValidNumericValue(application.pricing.total_transaction_fee)
                        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            currency(application.pricing.total_transaction_fee, {
                                currency: application.currency,
                            })
                        : NotAvailableJsx,
                ],
            },
            { type: "divider" },
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    const loanData = useMemo(() => [
        {
            label: t("dashboard.loan_information.amount"),
            values: [`${currency(Number(application.loanSize), { currency: application.currency })}`],
        },
        {
            label: t("dashboard.loan_information.duration"),
            values: [`${application.loanDuration} ${t("common.months")}`],
        },
        {
            label: t("common.monthlyRate"),
            values: [monthlyPayment],
        },
        ...(showCustomPricing
            ? [...loanDataCustomPricing]
            : [
                {
                    label: t("dashboard.loan_information.interestRate"),
                    values: [interestRate],
                },
            ]),
        {
            label: t("dashboard.overview.type"),
            values: [
                application.allBanksLoanPurpose
                    ? t(`common.loan_purpose.${application.allBanksLoanPurpose}`)
                    : "-",
            ],
        },
        {
            label: t("dashboard.loan_information.purpose"),
            values: [application.purposeDescription],
        },
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    const bankInfoData = useMemo(() => [
        ...(application.bankName
            ? [
                {
                    label: t("dashboard.overview.bankName"),
                    values: [application.bankName],
                },
            ]
            : []),
        ...(application.iban
            ? [
                {
                    label: t("dashboard.overview.iban"),
                    values: [application.iban],
                },
            ]
            : []),
    ], [application, t]);
    const leasingData = useMemo(() => {
        const insuranceRateCustomField = getCustomFieldConfig(customFields, AkfFieldName.insurance_rate, "pricing");
        const remainingValueCustomField = getCustomFieldConfig(customFields, AkfFieldName.remaining_value, "pricing");
        const leasingFactorCustomField = getCustomFieldConfig(customFields, AkfFieldName.leasing_factor, "pricing");
        return [
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_amount"),
                values: [
                    `${(currency(Number(application.loanSize)), { currency: application.currency })}`,
                ],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_duration"),
                values: [`${application.loanDuration} ${t("common.months")}`],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_factor"),
                values: [
                    isValidNumericValue(application.pricing.leasing_factor)
                        ? localizedNumber({
                            value: application.pricing.leasing_factor,
                            decimalPlaces: leasingFactorCustomField === null || leasingFactorCustomField === void 0 ? void 0 : leasingFactorCustomField.ui.precision,
                        })
                        : "-",
                ],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.service_rate"),
                values: [`${application.pricing.service_rate} ${t("common.per_month")}`],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.remaining_value"),
                values: [
                    (isValidNumericValue(application.pricing.remaining_value) &&
                        decimalToPercent(application.pricing.remaining_value, remainingValueCustomField === null || remainingValueCustomField === void 0 ? void 0 : remainingValueCustomField.ui.precision, true)) ||
                        "-",
                ],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.insurance_rate"),
                values: [
                    (isValidNumericValue(application.pricing.insurance_rate) &&
                        decimalToPercent(application.pricing.insurance_rate, insuranceRateCustomField === null || insuranceRateCustomField === void 0 ? void 0 : insuranceRateCustomField.ui.precision, true)) ||
                        "-",
                ],
            },
            {
                label: t("dashboard.loan_information.purpose"),
                values: [application.purposeDescription],
            },
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application, customFields]);
    const akfCustomFields = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return [
            ...(((_a = application.custom_fields_data) === null || _a === void 0 ? void 0 : _a.values)
                ? [
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.is_public_tender"),
                        values: [
                            ((_c = (_b = application.custom_fields_data) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.is_public_tender)
                                ? t("common.yes").toUpperCase()
                                : t("common.no").toUpperCase(),
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.employer_rate_subsidy"),
                        values: [
                            `${currency(Number((_e = (_d = application.custom_fields_data) === null || _d === void 0 ? void 0 : _d.values) === null || _e === void 0 ? void 0 : _e.employer_rate_subsidy), {
                                currency: application.currency,
                            })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.employer_service_subsidy"),
                        values: [
                            `${currency(Number((_g = (_f = application.custom_fields_data) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.employer_service_subsidy), { currency: application.currency })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.max_bikes_per_employee"),
                        values: [(_j = (_h = application.custom_fields_data) === null || _h === void 0 ? void 0 : _h.values) === null || _j === void 0 ? void 0 : _j.max_bikes_per_employee],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.price_limit_bike"),
                        values: [
                            `${currency(Number((_l = (_k = application.custom_fields_data) === null || _k === void 0 ? void 0 : _k.values) === null || _l === void 0 ? void 0 : _l.price_limit_bike), {
                                currency: application.currency,
                            })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.number_of_employees"),
                        values: [(_o = (_m = application.custom_fields_data) === null || _m === void 0 ? void 0 : _m.values) === null || _o === void 0 ? void 0 : _o.number_of_employees],
                    },
                ]
                : []),
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application.custom_fields_data]);
    const renderCardColumn = (data, cardTitle, cardEditKey, editHidden = false) => {
        return (_jsx(Col, Object.assign({ xs: 24 }, { children: _jsx(StyledCard, Object.assign({ title: cardTitle, extra: !editHidden &&
                    cardEditKey && (_jsx(Button, Object.assign({ type: "link", icon: _jsx(EditOutlined, {}), onClick: () => setEditKey(cardEditKey) }, { children: t("common.button_label.edit") }))) }, { children: data.length ? (data.map((item, index) => {
                    return "type" in item ? (_jsx("br", {}, index)) : (_jsx(DataItem, { label: `${item.label}:`, values: item.values, labelStyles: Object.assign({ opacity: 0.5, display: "flex", justifyContent: "end", textAlign: "right" }, item.labelStyles), labelColProps: { span: 8 } }, `data-item-${index}`));
                })) : (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("dashboard.overview.noInformationAdded") }))) })) })));
    };
    return (_jsxs(_Fragment, { children: [!!pendingItemsData.length && (_jsx(List, { header: _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("dashboard.pending_items.pendingItems") })), style: { background: "#FFF", borderColor: "rgba(0,0,0,0.06)" }, bordered: true, dataSource: pendingItemsData, renderItem: (item) => (_jsxs(ListItem, { children: [_jsx(Typography.Text, { children: item.text }), _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: item.onClick }, { children: item.buttonText }))] })) })), _jsxs(Row, Object.assign({ gutter: [24, 12] }, { children: [_jsx(Col, Object.assign({ lg: 12, xs: 24 }, { children: _jsxs(Row, { children: [renderCardColumn(customerData, t("dashboard.overview.customerInformation")), renderCardColumn(bankInfoData, t("dashboard.overview.bankInformation"), CardEditKeys.BankInfo, ![ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status))] }) })), _jsx(Col, Object.assign({ lg: 12, xs: 24 }, { children: _jsxs(Row, { children: [isAkf
                                    ? renderCardColumn(leasingData, t("dashboard.overview.leasing_information"))
                                    : renderCardColumn(loanData, t("dashboard.loan_information.loanInformation")), isAkf &&
                                    renderCardColumn(akfCustomFields, t("dashboard.overview.additional_application_fields"))] }) }))] })), editKey === CardEditKeys.Pricing && (_jsx(PricingModal, { baseInterestRate: ((_d = application.pricing) === null || _d === void 0 ? void 0 : _d.interest_rate) || 0, externalInterestRate: (_e = application.pricing) === null || _e === void 0 ? void 0 : _e.external_interest_rate, baseTransactionFee: application.pricing.transaction_fee || 0, externalTransactionFee: application.pricing.external_transaction_fee, currency: application.currency, modalProps: {
                    open: editKey === CardEditKeys.Pricing,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } })), _jsx(BankInfoModal, { bankName: application.bankName, iban: application.iban, modalProps: {
                    open: editKey === CardEditKeys.BankInfo,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } }), _jsx(TinNumberModal, { tin: application.taxIdNumber, inputLabel: _jsxs(Typography.Text, { children: [t("dashboard.company_representatives.tinFor"), " ", _jsx("b", { children: application.companyName })] }), modalProps: {
                    title: t("dashboard.overview.companyTin"),
                    open: editKey === CardEditKeys.Tin,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                }, representativesTins: representativesTins })] }));
};
const ListItem = styled(List.Item) `
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
`;
const EditIcon = styled(EditOutlined) `
	color: ${(props) => props.theme.color_primary};
	margin-left: 8px;
`;
const StyledCard = styled(Card) `
	margin-top: 15px;
	width: 100%;

	.ant-card-head-wrapper {
		height: 64px;
	}
`;
export default OverviewTab;
