import { jsx as _jsx } from "react/jsx-runtime";
import { Alert as AntdAlert } from "antd";
import styled from "styled-components";
const CustomAlert = styled(AntdAlert) `
	align-items: flex-start;

	span {
		margin-top: 4px;
	}
`;
const Alert = (props) => _jsx(CustomAlert, Object.assign({}, props));
export default Alert;
