import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { Table as AntdTable, Button, Col, Form, Input, Row, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import MainHeader from "@ui/main-header/MainHeader";
import { RoutePaths } from "src/Routes";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios } from "src/utils/Axios";
import CompanyPreview from "./CompanyPreview";
const DEFAULT_PAGE = "1";
const DEFAULT_PAGE_SIZE = "20";
const DIRECT_OPEN_COLUMN = "direct-open-column";
const Wrapper = styled.div `
	padding: 16px 24px;
`;
const Table = styled(AntdTable) `
	.${DIRECT_OPEN_COLUMN} {
		padding: 0 !important;
		width: 38px;
	}
`;
const CompaniesPortfolioPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [companies, setCompanies] = useState();
    const [pagination, setPagination] = useState();
    const [company, setCompany] = useState();
    const [form] = useForm();
    const showClearButton = Form.useWatch("search", form);
    usePageTitle(t("common.page_title.companies_portfolio"));
    const handleFilterChange = () => {
        //TODO when backend is ready
    };
    const page = searchParams.get("page") || DEFAULT_PAGE;
    const page_size = searchParams.get("page_size") || DEFAULT_PAGE_SIZE;
    const handleTableChange = (pagination) => {
        if ((pagination === null || pagination === void 0 ? void 0 : pagination.current) && pagination.pageSize) {
            const params = new URLSearchParams({
                page: pagination.current.toString(),
                page_size: pagination.pageSize.toString(),
            }).toString();
            setSearchParams(params);
        }
    };
    const getPortfolioCompanies = useCallback(({ page, page_size }) => {
        setLoading(true);
        Axios.get(`partner/portfolio?page=${page}&page_size=${page_size}`)
            .then(({ data }) => {
            setCompanies(data.companies);
            setPagination(data.pagination);
        })
            .catch((error) => Axios.error(error))
            .finally(() => setLoading(false));
    }, []);
    useEffect(() => {
        getPortfolioCompanies({ page, page_size });
    }, [page, page_size, getPortfolioCompanies]);
    const columns = [
        {
            className: DIRECT_OPEN_COLUMN,
            render: (company) => (_jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: (e) => {
                    e.stopPropagation();
                    navigate(generatePath(RoutePaths.CompanyPortfolioOverview, {
                        companyId: company.partner_portfolio_item_id,
                    }));
                } })),
        },
        {
            dataIndex: "company_name",
            title: t("companies.name"),
        },
        {
            dataIndex: "company_type",
            title: t("companies.type"),
        },
        {
            title: t("companies.address"),
            render: (company) => {
                return [
                    company.company_street,
                    company.company_house_number,
                    company.company_postcode,
                    company.company_location,
                    t(`common.countries.${company.company_country}`),
                ]
                    .filter(Boolean)
                    .join(", ");
            },
        },
        {
            dataIndex: "created_at",
            title: t("companies.created"),
            render(created_at) {
                return created_at ? dayjs(created_at).format(dayMonthYear) : "-";
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("common.page_title.companies_portfolio") }), _jsxs(Wrapper, { children: [_jsx(Form, Object.assign({ form: form, onValuesChange: handleFilterChange }, { children: _jsxs(Row, Object.assign({ gutter: 8 }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ name: "search" }, { children: _jsx(Input.Search, { placeholder: t("companies.search.search_placeholder") }) })) })), _jsx(Col, Object.assign({ span: 2 }, { children: showClearButton && (_jsx(Button, Object.assign({ type: "text", style: { color: "rgba(0,0,0,.45)" }, onClick: () => {
                                            form.resetFields();
                                            handleFilterChange();
                                        } }, { children: t("common.clear") }))) }))] })) })), _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: _jsxs(Trans, Object.assign({ i18nKey: "companies.search.search_showing" }, { children: [{ items: (companies === null || companies === void 0 ? void 0 : companies.length) || 0 }, " ", { of: (pagination === null || pagination === void 0 ? void 0 : pagination.total_rows) || 0 }] })) })), _jsx(Table, { style: { marginTop: 16 }, loading: loading, columns: columns, rowKey: "company_id", dataSource: companies, onChange: (pagination) => handleTableChange(pagination), pagination: {
                            pageSize: Number((pagination === null || pagination === void 0 ? void 0 : pagination.items_per_page) || page_size),
                            total: pagination === null || pagination === void 0 ? void 0 : pagination.total_rows,
                            current: Number((pagination === null || pagination === void 0 ? void 0 : pagination.current_page) || page),
                            position: ["bottomRight"],
                            showSizeChanger: true,
                        }, onRow: (record) => {
                            return {
                                onClick() {
                                    setCompany(record);
                                },
                            };
                        } })] }), _jsx(CompanyPreview, { company: company, onClose: () => setCompany(undefined) })] }));
};
export default CompaniesPortfolioPage;
