import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { RoutePaths } from "src/Routes";
import { usePageTitle } from "src/hooks/usePageTitle";
import { login } from "src/store/actions/userActions";
import { Axios } from "../utils/Axios";
const LoginPage = () => {
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePageTitle(t("common.page_title.login"));
    const onSubmit = (form) => {
        setSending(true);
        Axios.post("/partner/account/login", form)
            .then(() => dispatch(login()))
            .catch((error) => {
            void Axios.error(error, t("common.errors.login"));
        })
            .finally(() => {
            setSending(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Typography.Title, Object.assign({ level: 3, style: { marginBottom: "48px" } }, { children: t("dashboard.company_representatives.portalLogin") })), _jsxs(Form, Object.assign({ onFinish: onSubmit, layout: "vertical" }, { children: [_jsx(FormItem, Object.assign({ name: "email", label: t("dashboard.company_representatives.emailAddress"), labelAlign: "left", rules: [
                            {
                                required: true,
                                message: t("common.errors.fieldRequired"),
                            },
                        ] }, { children: _jsx(Input, { type: "email", placeholder: t("dashboard.company_representatives.emailExample") }) })), _jsx(FormItem, Object.assign({ name: "password", label: t("authentication.register.password"), labelAlign: "left", rules: [
                            {
                                required: true,
                                message: t("common.errors.fieldRequired"),
                            },
                        ] }, { children: _jsx(Input.Password, {}) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "48px" } }, { children: _jsx(Col, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", "data-cy": "login-button", loading: sending }, { children: t("common.button_label.login") })) }) })), _jsx(Row, Object.assign({ justify: "center", style: { marginTop: "16px" } }, { children: _jsx(Col, { children: _jsxs(Typography.Text, { children: [t("authentication.login.forgotPassword"), _jsx(Link, Object.assign({ to: RoutePaths.ForgotPassword, style: { marginLeft: "4px" } }, { children: t("authentication.login.clickHere") }))] }) }) }))] }))] }));
};
export default LoginPage;
