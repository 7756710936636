import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Space, Typography } from "antd";
import styled from "styled-components";
const PageHeaderLayout = ({ title, subtitle, extra, children }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsxs(Space, { children: [_jsxs(Typography.Text, Object.assign({ strong: true, style: { fontSize: subtitle ? 16 : 24 }, type: subtitle ? "secondary" : undefined }, { children: [title, " ", subtitle && " /"] })), subtitle && (_jsx(Typography.Text, Object.assign({ strong: true, style: { fontSize: 24 } }, { children: subtitle })))] }), extra] }), children] }));
};
const Wrapper = styled.div `
	padding: 30px 20px;
	background: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;

	.ant-page-header-heading {
		width: 100%;
	}
`;
export default PageHeaderLayout;
