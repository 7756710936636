import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Tooltip, message } from "antd";
import Text from "antd/es/typography/Text";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";
import Alert from "src/components/Alert";
import CompanyRepresentatives from "src/pages/application-drilldown/tabs/representatives/content/CompanyRepresentatives";
import { Axios } from "src/utils/Axios";
import CompanyRepresentativeEmpty from "./content/CompanyRepresentativeEmpty";
import CompanyRepresentativeInfo from "./content/CompanyRepresentativeInfo";
const statusesRepButtonHidden = [
    ApplicationStatuses.InternalReview,
    ApplicationStatuses.Pricing,
    ApplicationStatuses.InternalPricing,
    ApplicationStatuses.Rejected,
    ApplicationStatuses.AwaitingPayout,
    ApplicationStatuses.PaidOut,
];
const RepresentativesTab = ({ applicationStatus, representatives, onUpdate, ownershipPercentageExceeded, noSoleSignatory, companyTin, }) => {
    const { t } = useTranslation();
    const { application_id } = useParams();
    const [repModal, setRepModal] = useState(false);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [repModalRepresentative, setRepModalRepresentative] = useState();
    const { countries } = useSelector((state) => state.configState);
    const addRepBtnDisabled = ![ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(applicationStatus);
    const saveRepresentative = (newRep) => {
        const saved = () => {
            void message.success(t("common.saved"));
            onUpdate();
            setRepModal(false);
            setRepModalRepresentative(undefined);
        };
        const error = (error) => {
            void Axios.error(error, t("common.errors.sendData"));
        };
        if (newRep.signatory_id) {
            // update representative
            Axios.patch(`/partner/signatories/${newRep.signatory_id}`, newRep).then(saved, error);
        }
        else {
            // add new representative
            Axios.post(`/partner/applications/${application_id}/signatories`, {
                data: [newRep],
            }).then(saved, error);
        }
    };
    return (_jsxs("div", Object.assign({ style: { width: "100%" }, "data-cy": "company-representatives-tab" }, { children: [representatives.length ? (_jsxs(_Fragment, { children: [noSoleSignatory && (_jsx(Alert, { type: "error", message: t("common.errors.err_application_signatories_sole_or_atleast_2"), showIcon: true, style: { marginBottom: 24 } })), ownershipPercentageExceeded && (_jsx(Alert, { type: "error", message: t("common.errors.err_share_percentage_greater_then_100"), showIcon: true, style: { marginBottom: 24 } })), !statusesRepButtonHidden.includes(applicationStatus) && (_jsxs(Row, Object.assign({ align: "middle", justify: "space-between", gutter: [0, 16], style: { marginBottom: 24 } }, { children: [_jsx(Col, { children: _jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsx(QuestionCircleOutlined, { style: { marginRight: 4 } }), t("dashboard.company_representatives.not-sure-who-add"), " ", _jsx(Button, Object.assign({ type: "link", onClick: () => setIsInfoModalVisible(true), style: { padding: 0 } }, { children: t("dashboard.company_representatives.not-sure-who-add-link-text") }))] })) }), _jsx(Col, { children: _jsx(Tooltip, Object.assign({ title: addRepBtnDisabled
                                        ? t("dashboard.company_representatives.representativeButtonDisabled")
                                        : "" }, { children: _jsx(Button, Object.assign({ size: "large", type: "primary", "data-cy": "add-representative-button", icon: _jsx(PlusOutlined, {}), disabled: addRepBtnDisabled, onClick: () => {
                                            setRepModal(true);
                                        } }, { children: t("common.button_label.addRepresentative") })) })) })] }))), _jsx(CompanyRepresentatives, { representatives: representatives, applicationStatus: applicationStatus, onEdit: (rep) => setRepModalRepresentative(rep), onUpdate: onUpdate, companyTin: companyTin })] })) : (_jsx(CompanyRepresentativeEmpty, { onClick: () => setRepModal(true) })), [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(applicationStatus) &&
                (repModal || !!repModalRepresentative) && (_jsx(RepresentativeModal, { save: saveRepresentative, close: () => {
                    setRepModal(false);
                    setRepModalRepresentative(undefined);
                }, representative: repModalRepresentative, countries: countries, companyTin: companyTin })), _jsx(Modal, Object.assign({ open: isInfoModalVisible, onCancel: () => setIsInfoModalVisible(false), onOk: () => setIsInfoModalVisible(false), cancelButtonProps: {
                    style: {
                        display: "none",
                    },
                }, width: 633 }, { children: _jsx(CompanyRepresentativeInfo, {}) }))] })));
};
export default RepresentativesTab;
