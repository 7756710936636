import { UserActions } from "src/store/actions/userActions";
const defaultState = {
    logged: localStorage.getItem("isLogged") === "true",
    userId: undefined,
    loggedOutByUser: false,
};
const userReducer = function (state = defaultState, action) {
    var _a, _b;
    switch (action.type) {
        case UserActions.LOGIN:
            localStorage.setItem("isLogged", "true");
            return Object.assign(Object.assign({}, state), { logged: true, loggedOutByUser: false });
        case UserActions.LOGOUT:
            localStorage.removeItem("isLogged");
            return Object.assign(Object.assign({}, state), { userId: "", logged: false, loggedOutByUser: !!((_a = action.payload) === null || _a === void 0 ? void 0 : _a.loggedOutByUser) });
        case UserActions.SET_USER:
            return Object.assign(Object.assign({}, state), { userId: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.userInfo.userId });
        default:
            return state;
    }
};
export default userReducer;
