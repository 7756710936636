var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generate } from "@ant-design/colors";
import { ConfigProvider, Spin, theme } from "antd";
import styled, { ThemeProvider } from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import { LocaleProvider } from "@teylor-tools/utils/LocaleProvider";
import { useTranslations } from "@ui/translations/translations";
import { setConfig, setCountryList } from "src/store/actions/configAction";
import { setUser } from "src/store/actions/userActions";
import { Axios } from "src/utils/Axios";
const defaultTheme = {
    main_menu_bg_color: "#073675",
    main_menu_color: "#FFFFFF",
    color_link: "#26ACE1",
    color_primary: "#26ACE1",
    color_cta_text: "#FFFFFF",
    logo_web_path: "",
    logo_collapsed_web_path: "",
};
const SpinWrapper = styled.div `
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const findFeature = (data, feature) => data.public_features.find((f) => f.name === feature);
const AppConfig = ({ children }) => {
    const { i18n } = useTranslation();
    const { countries } = useTranslations();
    const dispatch = useDispatch();
    const { logged } = useSelector((state) => state.userState);
    const { preferredCountries } = useSelector((state) => state.configState);
    const [appTheme, setAppTheme] = useState();
    const [antdTheme, setAntdTheme] = useState();
    const [locale, setLocale] = useState();
    const [isLoading, setConfigLoading] = useState(true);
    const { token } = theme.useToken();
    const getConfig = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        setConfigLoading(true);
        try {
            if (!logged) {
                const { data } = yield Axios.get("/config");
                const theme = findFeature(data, FeatureName.FeThemePartner);
                handleTheme(theme === null || theme === void 0 ? void 0 : theme.value.fe_theme);
                setConfigLoading(false);
                return;
            }
            const profile = yield Axios.get("partner/account/profile");
            if (profile.data.interface_language) {
                void i18n.changeLanguage(profile.data.interface_language);
            }
            dispatch(setUser({ userId: profile.data.entity_id }));
            const { data } = yield Axios.get("/config", {
                partner_key: profile.data.partner_key,
            });
            const applicationTypes = (_a = data.partner_config) === null || _a === void 0 ? void 0 : _a.application_types;
            const customFields = (_b = data.partner_config) === null || _b === void 0 ? void 0 : _b.custom_fields_configs;
            const entitlements = (_c = data.partner_config) === null || _c === void 0 ? void 0 : _c.entitlements;
            const companyTypes = findFeature(data, FeatureName.CompanyTypes);
            const preferredCountries = findFeature(data, FeatureName.ApplicationCountries);
            const currencies = findFeature(data, FeatureName.Currencies);
            setLocale((_d = data.partner_config) === null || _d === void 0 ? void 0 : _d.locale);
            dispatch(setConfig({
                applicationTypes,
                companyTypes,
                customFields,
                currencies,
                entitlements,
                preferredCountries,
            }));
            setConfigLoading(false);
            if (!appTheme) {
                const theme = findFeature(data, FeatureName.FeThemePartner);
                theme && handleTheme((_e = theme === null || theme === void 0 ? void 0 : theme.value) === null || _e === void 0 ? void 0 : _e.fe_theme);
            }
        }
        catch (_f) {
            setConfigLoading(false);
            dispatch(setConfig({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [logged]);
    useEffect(() => {
        if (!isLoading) {
            dispatch(setCountryList({ preferredCountries, countries }));
        }
    }, [countries, dispatch, preferredCountries, isLoading]);
    const handleTheme = (theme) => {
        const colorLink = (theme === null || theme === void 0 ? void 0 : theme.color_link) || defaultTheme.color_link;
        setAntdTheme({
            colorPrimary: (theme === null || theme === void 0 ? void 0 : theme.color_primary) || defaultTheme.color_primary,
            colorTextLightSolid: (theme === null || theme === void 0 ? void 0 : theme.color_cta_text) || defaultTheme.color_cta_text,
            colorLink,
            colorLinkHover: colorLink && generate(colorLink)[4],
            colorLinkActive: colorLink && generate(colorLink)[6],
        });
        setAppTheme(Object.assign(Object.assign({}, defaultTheme), theme));
    };
    useEffect(() => void getConfig(), [getConfig]);
    if (!appTheme || !antdTheme || isLoading) {
        return (_jsx(SpinWrapper, { children: _jsx(Spin, {}) }));
    }
    return (_jsx(ConfigProvider, Object.assign({ theme: { token: antdTheme } }, { children: _jsx(ThemeProvider, Object.assign({ theme: Object.assign(Object.assign(Object.assign({}, token), antdTheme), appTheme) }, { children: _jsx(LocaleProvider, Object.assign({ locale: locale }, { children: children })) })) })));
};
export default AppConfig;
