import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { Space, Table, Timeline, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import timePassedUntilNow from "@teylor-tools/utils/timePassedUntilNow";
import { DataCard, DataCardDivider, DataCardItem } from "@ui/data-display/DataCard";
import IndustryCodesCard from "@ui/industry-codes-card/IndustryCodesCard";
import { RoutePaths } from "src/Routes";
import { CompanyStatusTag } from "src/components/CompanyStatusTag";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
const Wrapper = styled.div `
	margin: 24px;
	display: grid;
	column-gap: 32px;
	align-items: start;
	grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
`;
const DataCardEvents = styled(DataCard) `
	max-height: 700px;
	overflow-y: auto;
`;
const pickTimePassed = (foundationDate, t) => {
    const { monthsPassed, yearsPassed } = timePassedUntilNow(foundationDate);
    return `${t("company.year_other", { count: yearsPassed })} ${t("company.month_other", {
        count: monthsPassed,
    })}`;
};
const CompanyOverviewPage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { company, globalCompanyData, loadingGlobalCompanyData } = useCompany();
    const { t } = useTranslation();
    const columns = [
        {
            dataIndex: "first_name",
            title: t("company.person.first_name"),
            width: 140,
        },
        {
            dataIndex: "last_name",
            title: t("company.person.last_name"),
            width: 140,
        },
        {
            dataIndex: "company_relation_description",
            title: t("company.person.role"),
            width: 150,
        },
        {
            dataIndex: "address",
            title: t("company.address"),
            render: (address) => {
                return address.formattedValue || "-";
            },
        },
    ];
    const companyRepresentatives = (_a = globalCompanyData === null || globalCompanyData === void 0 ? void 0 : globalCompanyData.persons) === null || _a === void 0 ? void 0 : _a.slice(0, 4);
    return (_jsxs(Wrapper, { children: [_jsxs("div", { children: [_jsxs(DataCard, Object.assign({ loading: !company }, { children: [_jsx(DataCardItem, { label: t("company.name"), value: _jsx("strong", { children: company.company_name }) }), _jsx(DataCardItem, { label: t("company.business_purpose"), value: company.description }), _jsx(DataCardItem, { label: t("company.status"), value: company.company_status && _jsx(CompanyStatusTag, { status: company.company_status }) }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.address"), value: [
                                    company.company_street,
                                    company.company_house_number,
                                    company.company_postcode,
                                    company.company_location,
                                ]
                                    .filter(Boolean)
                                    .join(", ") }), _jsx(DataCardItem, { label: t("company.country"), value: company.company_country
                                    ? t(`common.countries.${company.company_country}`)
                                    : "-" }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.hr_number"), value: company.hr_number }), _jsx(DataCardItem, { label: t("company.tax_id"), value: company.tax_id_number }), _jsx(DataCardItem, { label: t("company.client_id"), value: company.company_id }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.legal_form"), value: company.company_type }), _jsx(DataCardItem, { label: t("company.founded"), value: company.foundation_date && new Date(company.foundation_date).getFullYear() }), _jsx(DataCardItem, { label: t("company.age"), value: company.foundation_date && pickTimePassed(company.foundation_date, t) }), _jsx(DataCardItem, { label: t("company.employees"), value: company.number_of_employees })] })), _jsxs(DataCard, Object.assign({ loading: !company }, { children: [_jsx(DataCardItem, { label: t("company.phone_number"), value: (_b = company.contact_info) === null || _b === void 0 ? void 0 : _b.telephone }), _jsx(DataCardItem, { label: t("company.fax"), value: (_c = company.contact_info) === null || _c === void 0 ? void 0 : _c.fax }), _jsx(DataCardItem, { label: t("company.email"), value: (_d = company.contact_info) === null || _d === void 0 ? void 0 : _d.email }), _jsx(DataCardItem, { label: t("company.website"), value: ((_e = company.contact_info) === null || _e === void 0 ? void 0 : _e.website) ? (_jsx(Typography.Link, Object.assign({ href: company.contact_info.website, target: "_blank", rel: "noopener noreferrer" }, { children: (_f = company === null || company === void 0 ? void 0 : company.contact_info) === null || _f === void 0 ? void 0 : _f.website }))) : ("-") }), _jsx(DataCardItem, { label: t("company.last_updated"), value: company.company_last_updated_at
                                    ? dayjs(company.company_last_updated_at).format(dayMonthYear)
                                    : "-" })] })), company.industry_codes && _jsx(IndustryCodesCard, { industryCodes: company.industry_codes }), _jsxs(DataCard, Object.assign({ loading: loadingGlobalCompanyData, title: t("company.persons"), extra: _jsx(Link, Object.assign({ to: generatePath(RoutePaths.CompanyPortfolioPersons, {
                                companyId: company.partner_portfolio_item_id,
                            }) }, { children: t("companies.persons.view_all") })) }, { children: [_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: _jsxs(Trans, Object.assign({ i18nKey: "companies.persons.search_showing" }, { children: [{ items: (companyRepresentatives === null || companyRepresentatives === void 0 ? void 0 : companyRepresentatives.length) || 0 }, { of: (_g = globalCompanyData === null || globalCompanyData === void 0 ? void 0 : globalCompanyData.persons) === null || _g === void 0 ? void 0 : _g.length }] })) })), _jsx(Table, { style: { marginTop: 16 }, columns: columns, rowKey: "external_person_id", dataSource: companyRepresentatives, pagination: false })] }))] }), _jsx(DataCardEvents, Object.assign({ loading: loadingGlobalCompanyData }, { children: _jsx(Timeline, { items: (_h = globalCompanyData === null || globalCompanyData === void 0 ? void 0 : globalCompanyData.events) === null || _h === void 0 ? void 0 : _h.map(({ description, type, date }) => {
                        return {
                            children: (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Typography.Text, Object.assign({ strong: true }, { children: `${dayjs(date).format(dayMonthYear)} - ${type}` })), _jsx(Typography.Text, { children: description })] }))),
                        };
                    }) }) }))] }));
};
export default CompanyOverviewPage;
