var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Tag as AntdTag, Button, Space, Table, Typography, message } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { InviteStatus, } from "@teylor-tools/Api";
import { Axios } from "src/utils/Axios";
import InviteUserModal from "./modals/InviteUserModal";
const { Text } = Typography;
const Tag = styled(AntdTag) `
	border-radius: 16px;
	border: 0;
	padding: 2px 8px;
`;
var SignatureStatus;
(function (SignatureStatus) {
    SignatureStatus["kycOpen"] = "kycOpen";
    SignatureStatus["kycComplete"] = "kycComplete";
    SignatureStatus["inviteExpired"] = "inviteExpired";
    SignatureStatus["invited"] = "invited";
    SignatureStatus["notInvited"] = "notInvited";
})(SignatureStatus || (SignatureStatus = {}));
const signatureStatusColoursMapping = {
    [SignatureStatus.kycOpen]: "blue",
    [SignatureStatus.kycComplete]: "green",
    [SignatureStatus.inviteExpired]: "red",
    [SignatureStatus.invited]: "cyan",
    [SignatureStatus.notInvited]: "default",
};
const isKycComplete = (kycStatus) => {
    return Object.keys(kycStatus).every((key) => kycStatus[key].is_required
        ? kycStatus[key].is_completed
        : true);
};
const getSignatureStatus = (signatory) => {
    switch (signatory.invite_status) {
        case InviteStatus.Accepted: {
            return signatory.kyc_status && isKycComplete(signatory.kyc_status)
                ? SignatureStatus.kycComplete
                : SignatureStatus.kycOpen;
        }
        case InviteStatus.NotInvited:
            return SignatureStatus.notInvited;
        case InviteStatus.NotAccepted:
            return SignatureStatus.invited;
        case InviteStatus.Expired:
            return SignatureStatus.inviteExpired;
        default:
            return SignatureStatus.notInvited;
    }
};
const SignatureRequestsTab = ({ signatories, getRepresentatives }) => {
    const { t } = useTranslation();
    const { application_id } = useParams();
    const [signatoriesData, setSignatoriesData] = useState();
    const [signatory, setSignatory] = useState();
    useEffect(() => {
        const prepareSignatoriesToDisplay = () => __awaiter(void 0, void 0, void 0, function* () {
            return yield Promise.all(signatories.map((signatory) => {
                const status = getSignatureStatus(signatory);
                return Object.assign(Object.assign({}, signatory), { signatureStatus: status });
            }));
        });
        prepareSignatoriesToDisplay()
            .then((signatoriesData) => setSignatoriesData(signatoriesData))
            .catch(() => setSignatoriesData(undefined));
    }, [signatories]);
    const cancelInvite = (signatoryId) => {
        Axios.delete(`/partner/applications/${application_id}/signatories/${signatoryId}/invite`)
            .then(() => {
            void message.info(t("dashboard.signature.invitationCancelled"));
            void getRepresentatives();
        })
            .catch((err) => void Axios.error(err, t("common.errors.cancelInvitationFailed")));
    };
    const columns = [
        {
            key: "name",
            title: t("dashboard.overview.name"),
            render: (_, signatory) => `${signatory.first_name} ${signatory.last_name}`,
        },
        {
            key: "documents",
            title: t("dashboard.signature.documentsToSign"),
            render: (_, signatory) => {
                var _a, _b;
                return (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.is_loan_signature_required) && (_jsx(Text, { children: t("dashboard.signature.loanContract") })), ((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.is_ubo_signature_required) && (_jsx(Text, { children: t("dashboard.signature.personalGuarantee") }))] })));
            },
        },
        {
            key: "status",
            title: t("dashboard.overview.status"),
            render: (_, signatory) => (_jsx(Tag, Object.assign({ color: signatureStatusColoursMapping[signatory.signatureStatus] }, { children: t(`dashboard.signature.status.${signatory.signatureStatus}`) }))),
        },
        {
            key: "last_status_change",
            title: t("dashboard.overview.lastStatusChange"),
            render: (_, signatory) => {
                return (_jsx(Text, { children: signatory.invite_status_updated_at
                        ? dayjs(signatory.invite_status_updated_at).format("DD MMMM, YYYY")
                        : "-" }));
            },
        },
        {
            key: "buttons",
            align: "right",
            render: (_, signatory) => {
                const invite = signatory.invite_status &&
                    [InviteStatus.Accepted, InviteStatus.NotInvited].includes(signatory.invite_status);
                return (signatory.kyc_status &&
                    !isKycComplete(signatory.kyc_status) && (_jsxs(Space, { children: [_jsx(Button, Object.assign({ type: invite ? "primary" : "default", disabled: signatory.invite_status === InviteStatus.Accepted, onClick: () => setSignatory(signatory) }, { children: invite ? t("common.button_label.inviteToSign") : t("common.button_label.reInvite") })), _jsx(Button, Object.assign({ type: "link", disabled: signatory.invite_status &&
                                [InviteStatus.Expired, InviteStatus.NotInvited].includes(signatory.invite_status), onClick: () => cancelInvite(signatory.signatory_id) }, { children: t("common.button_label.cancelInvite") }))] })));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Table, { dataSource: signatoriesData, rowKey: "signatory_id", loading: !signatoriesData, columns: columns, pagination: false }), signatory && (_jsx(InviteUserModal, { signatory: signatory, setSignatory: setSignatory, getRepresentatives: getRepresentatives }))] }));
};
export default SignatureRequestsTab;
