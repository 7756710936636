import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { CompanyPartnerStatus } from "@teylor-tools/Api";
const pickColor = (status) => {
    switch (status) {
        case CompanyPartnerStatus.Liquidation:
            return "gold";
        case CompanyPartnerStatus.Active:
            return "blue";
        case CompanyPartnerStatus.Terminated:
            return "red";
        default:
            return "default";
    }
};
export const CompanyStatusTag = ({ status }) => {
    const { t } = useTranslation();
    return _jsx(Tag, Object.assign({ color: pickColor(status) }, { children: t(`company.status.${status}`) }));
};
