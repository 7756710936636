import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, generatePath } from "react-router-dom";
import { PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { Table as AntdTable, Button, Col, Input, Row, Space } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { RoutePaths } from "src/Routes";
import Error from "src/components/Error";
import { colors } from "src/helpers/variables";
import { usePageTitle } from "src/hooks/usePageTitle";
import PageHeaderLayout from "src/layouts/PageHeaderLayout/PageHeaderLayout";
import ApplicationInfo from "src/pages/applications/ApplicationInfo";
import { Axios } from "src/utils/Axios";
import { getApplicationStatuses, getDisplayedStatus, } from "src/utils/applicationStatus";
const DIRECT_OPEN_COLUMN = "direct-open-column";
const Wrapper = styled(PageHeaderLayout) `
	width: 100%;
`;
const WrappedContent = styled(Space) `
	width: 100%;
	padding: 0 20px;
`;
const Table = styled(AntdTable) `
	border: 1px solid rgba(0, 0, 0, 0.05);

	.ant-table-row.active {
		background: ${colors.antd_light_grey};
	}

	.${DIRECT_OPEN_COLUMN} {
		padding: 0 !important;
		width: 38px;
	}
`;
let searchTimeout;
const createStatusFilter = (filters, t) => {
    if (!(filters === null || filters === void 0 ? void 0 : filters.all_statuses))
        return [];
    return filters.all_statuses.reduce((aggr, status) => {
        const displayedStatus = getDisplayedStatus(status);
        if (!displayedStatus) {
            return aggr;
        }
        if (aggr.some((status) => status.value === displayedStatus)) {
            return aggr;
        }
        return [
            ...aggr,
            { text: t(`common.application_status.${displayedStatus}`), value: displayedStatus },
        ];
    }, []);
};
const ApplicationsPage = () => {
    const { t } = useTranslation();
    const { currency } = useFormatter();
    const [error, setError] = useState();
    const [applications, setApplications] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        currentPage: 1,
        size: 20,
    });
    const [totalRows, setTotalRows] = useState();
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState();
    const [activeFilters, setActiveFilters] = useState({});
    const { defaultCurrency } = useSelector((state) => state.configState);
    usePageTitle(t("common.page_title.applicationList"));
    const onTableChange = (paginationConfig, filters) => {
        const statusFilterValues = filters.status
            ? filters.status.flatMap((val) => getApplicationStatuses(val))
            : [];
        const allBanksLoanPurposeFilterValues = filters.allBanksLoanPurpose
            ? filters.allBanksLoanPurpose.map((loanPurpose) => loanPurpose.toString())
            : [];
        setActiveFilters(Object.assign(Object.assign({}, (statusFilterValues.length ? { status: statusFilterValues.join() } : {})), (allBanksLoanPurposeFilterValues.length
            ? { allBanksLoanPurpose: allBanksLoanPurposeFilterValues.join() }
            : {})));
        setPagination({
            currentPage: paginationConfig.current || pagination.currentPage,
            size: paginationConfig.pageSize || pagination.size,
        });
    };
    const getApplications = () => {
        setLoading(true);
        Axios.get(`/partner/applications`, Object.assign(Object.assign({ page: pagination.currentPage, page_size: pagination.size }, (search ? { text: search } : {})), activeFilters))
            .then(({ data }) => {
            setTotalRows(data.pagination.total_rows || 0);
            setApplications(data.result);
        }, (err) => setError(err))
            .finally(() => setLoading(false));
    };
    const getFilters = () => {
        setLoading(true);
        Axios.get("/partner/filter_values")
            .then(({ data }) => setFilters(data))
            .catch((err) => {
            void Axios.error(err, t("common.errors.generic"));
            setError(err);
        })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(getApplications, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    useEffect(getApplications, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, activeFilters, pagination]);
    useEffect(getFilters, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const columns = useMemo(() => {
        var _a;
        return ((_a = filters === null || filters === void 0 ? void 0 : filters.application_types) === null || _a === void 0 ? void 0 : _a.length)
            ? [
                {
                    render: (a) => (_jsx(Button, { type: "link", icon: _jsx(SelectOutlined, {}), onClick: () => navigate(generatePath(RoutePaths.ApplicationDrilldown, {
                            application_id: a.application_id,
                        })) })),
                    className: `${DIRECT_OPEN_COLUMN} cy-view-application`,
                },
                {
                    key: "short_id",
                    title: t("dashboard.overview.applicationId"),
                    dataIndex: "short_id",
                    render: (value) => value.toUpperCase(),
                },
                {
                    key: "created_at",
                    title: t("dashboard.overview.createdAt"),
                    dataIndex: "created_at",
                    render: (value) => value.split("T")[0],
                },
                {
                    key: "amount",
                    title: t("dashboard.loan_information.amount"),
                    dataIndex: "amount",
                    render: (val) => currency(val, { currency: defaultCurrency }),
                },
                {
                    key: "duration",
                    title: t("dashboard.loan_information.duration"),
                    dataIndex: "duration",
                    render: (value) => `${value} ${t("common.months")}`,
                },
                {
                    key: "status",
                    title: t("dashboard.overview.status"),
                    dataIndex: "status",
                    render: (value) => {
                        const status = getDisplayedStatus(value);
                        return status ? t(`common.application_status.${status}`) : "";
                    },
                    filters: createStatusFilter(filters, t),
                    onFilter: (val, record) => getApplicationStatuses(val).includes(record.status),
                },
                {
                    key: "company",
                    title: t("dashboard.company_information.company"),
                    dataIndex: "company_name",
                },
                {
                    key: "allBanksLoanPurpose",
                    title: t("dashboard.loan_information.loanType"),
                    render: (item) => item.allBanksLoanPurpose
                        ? t(`common.loan_purpose.${item.allBanksLoanPurpose}`)
                        : "-",
                    filters: filters.application_types.map((type) => ({
                        text: t(`common.loan_purpose.${type}`),
                        value: type,
                    })),
                    ellipsis: true,
                    onFilter: (value, record) => record.allBanksLoanPurpose === value,
                },
            ]
            : [];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]);
    if (error)
        return _jsx(Error, { error: error });
    return (_jsx(Wrapper, Object.assign({ title: t("common.label.applications"), extra: _jsx(Link, Object.assign({ to: RoutePaths.Apply }, { children: _jsx(Button, Object.assign({ size: "large", type: "primary", icon: _jsx(PlusOutlined, {}) }, { children: t("common.button_label.newApplication") })) })) }, { children: _jsxs(WrappedContent, Object.assign({ direction: "vertical", size: 20 }, { children: [_jsx(Input, { placeholder: t("dashboard.applicationsSearch"), onChange: (evt) => setSearch(evt.target.value) }), _jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 17 }, { children: _jsx(Table
                            // @ts-ignore TODO: fix types for columns
                            , { 
                                // @ts-ignore TODO: fix types for columns
                                columns: columns, dataSource: applications, rowKey: "application_id", loading: loading, onChange: (pagination, filters) => onTableChange(pagination, filters), pagination: {
                                    position: ["bottomCenter"],
                                    pageSize: pagination.size,
                                    total: totalRows,
                                    showSizeChanger: true,
                                }, onRow: (record) => {
                                    return {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            setSelectedAppId(record.application_id);
                                        },
                                    };
                                }, rowClassName: (record) => record.application_id === selectedAppId ? "active" : "" }) })), _jsx(Col, Object.assign({ span: 7 }, { children: _jsx(ApplicationInfo, { appId: selectedAppId, currency: defaultCurrency }) }))] }))] })) })));
};
export default ApplicationsPage;
