import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Space, Typography } from "antd";
import styled from "styled-components";
const ContentWrapperNarrow = styled.div `
	box-sizing: border-box;
	width: 567px;
	margin: 100px auto 0 auto;

	@media (max-width: 767px) {
		max-width: 567px;
		width: auto;
	}
`;
const ContentCard = styled.div `
	background-color: #ffffff;
	padding: 40px 64px 80px;
	margin-top: 64px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media (max-width: 992px) {
		padding: 32px 48px;
		margin-top: 32px;
	}

	@media (max-width: 576px) {
		padding: 16px 24px;
		margin-top: 24px;
	}
`;
const CloseCircleIcon = styled(CloseCircleOutlined) `
	color: var(--teylor-light-gray);
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;
const Divider = styled(AntdDivider) `
	margin: 32px 0;
`;
const ContactItem = styled.div `
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 8px;
	align-items: center;
`;
const { Title, Text } = Typography;
const Error = ({ error }) => {
    const { t } = useTranslation();
    return (_jsx(ContentWrapperNarrow, { children: _jsxs(ContentCard, { children: [_jsx(CloseCircleIcon, {}), _jsx(Title, Object.assign({ level: 3, style: { textAlign: "center" } }, { children: t("not_found_page.genericErrorTitle") })), error && error.code && (_jsxs(Text, Object.assign({ strong: true, type: "secondary", style: { textAlign: "center" } }, { children: [t("not_found_page.errorCode"), ": ", error.code] }))), _jsx(Divider, {}), _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Text, Object.assign({ type: "secondary" }, { children: t("not_found_page.genericErrorInfo") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("not_found_page.genericErrorInfo2") })), _jsxs(Text, Object.assign({ type: "secondary" }, { children: [_jsxs(ContactItem, { children: [_jsx(PhoneOutlined, {}), _jsx(Text, Object.assign({ type: "secondary" }, { children: "+49 7531 9573074" }))] }), _jsxs(ContactItem, { children: [_jsx(MailOutlined, {}), _jsx("a", Object.assign({ href: "mailto:partner@teylor.com" }, { children: "partner@teylor.com" }))] })] }))] }))] }) }));
};
export default Error;
