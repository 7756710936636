import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import styled from "styled-components";
import { usePageTitle } from "src/hooks/usePageTitle";
import PageHeaderLayout from "src/layouts/PageHeaderLayout/PageHeaderLayout";
import AccountTab from "./tabs/profile/AccountTab";
const { TabPane } = Tabs;
const SettingsPage = () => {
    const { t } = useTranslation();
    usePageTitle(t("common.page_title.settings"));
    return (_jsx(Wrapper, Object.assign({ title: t("common.label.settings") }, { children: _jsx(TabsGroup, { children: _jsx(TabPane, Object.assign({ tab: t("settings.account") }, { children: _jsx(AccountTab, {}) }), "1") }) })));
};
const Wrapper = styled(PageHeaderLayout) `
	width: 100%;
`;
const TabsGroup = styled(Tabs) `
	padding: 0 20px;

	.ant-tabs-nav-wrap {
		background: #fff;
	}
`;
export default SettingsPage;
