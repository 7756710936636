import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Space, Typography } from "antd";
import styled from "styled-components";
const RejectModal = ({ modalProps }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, Object.assign({ title: _jsxs(Space, { children: [_jsx(WarningIcon, {}), _jsx(Typography.Text, { children: t("common.messages.areYouSure") })] }), okButtonProps: { danger: true }, okText: t("common.button_label.rejectApplication"), destroyOnClose: true }, modalProps, { children: _jsx(Typography.Text, { children: t("common.messages.beforeRejectConfirm") }) })));
};
const WarningIcon = styled(ExclamationCircleOutlined) `
	color: #ff4d4f;
`;
export default RejectModal;
