import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { TranslationsProvider as TranslationsProviderUI, } from "@ui/translations/translations";
export const TranslationsProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const translationsUI = useMemo(() => ({
        add: t("common.button_label.add"),
        save: t("common.button_label.save"),
        yes: t("common.yes"),
        no: t("common.no"),
        cancel: t("common.button_label.cancel"),
        select: t("common.select"),
        edit: t("common.button_label.edit"),
        delete: t("common.button_label.delete"),
        city: t("dashboard.company_representatives.city"),
        cityPlaceholder: t("application_process.company_details.placeholder.city"),
        street: t("dashboard.company_representatives.street"),
        streetPlaceholder: t("application_process.company_details.placeholder.street"),
        emailInputErrorMessage: t("common.errors.emailInvalid"),
        emailInputLabel: t("dashboard.company_representatives.emailAddress"),
        emailInputPlaceholder: t("dashboard.company_representatives.emailAddress"),
        postcode: t("dashboard.company_representatives.postcode"),
        postcodePlaceholder: t("application_process.company_details.placeholder.postcode"),
        hrNumber: t("application_process.company_details.hrNumber"),
        hrNumberPlaceholder: t("application_process.company_details.placeholder.hrNumber"),
        houseNumber: t("dashboard.company_representatives.houseNumber"),
        houseNumberPlaceholder: t("application_process.company_details.placeholder.houseNumber"),
        countries: getCountryList(i18n.language),
        country: t("dashboard.company_representatives.country"),
        representativeCard: {
            address: t("dashboard.company_representatives.address"),
            addTin: t("common.addTin"),
            beneficialOwner: t("dashboard.company_representatives.beneficialOwner"),
            canSignAlone: t("dashboard.company_representatives.canSignAlone"),
            dateOfBirth: t("dashboard.company_representatives.dateOfBirth"),
            email: t("dashboard.company_representatives.emailAddress"),
            legalSignatory: t("dashboard.company_representatives.legalSignatory"),
            nationality: t("dashboard.company_representatives.nationality"),
            ownership: t("common.ownership"),
            phone: t("dashboard.company_representatives.phoneNumber"),
            tin: t("application_process.company_details.tin"),
            willingToSign: t("dashboard.company_representatives.willingToSign"),
            creditScore: t("dashboard.company_representatives.creditScore"),
            lastUpdated: t("dashboard.company_representatives.lastUpdated"),
        },
        representativeModal: {
            addRepresentative: t("dashboard.company_representatives.addCompanyRepresentative"),
            allFieldsRequired: t("common.errors.fieldRequired"),
            beneficialOwner: t("dashboard.company_representatives.beneficialOwner"),
            canSignAlone: t("dashboard.company_representatives.canSignAlone"),
            dateOfBirth: t("dashboard.company_representatives.dateOfBirth"),
            editRepresentative: t("dashboard.company_representatives.editCompanyRepresentative"),
            firstName: t("dashboard.company_representatives.firstName"),
            houseNumber: t("dashboard.company_representatives.houseNumber"),
            lastName: t("dashboard.company_representatives.lastName"),
            legalSignatory: t("dashboard.company_representatives.legalSignatory"),
            nationality: t("dashboard.company_representatives.nationality"),
            optional: t("common.canAddLater"),
            percentageOwnership: t("dashboard.company_representatives.percentageOwnership"),
            phoneNumber: t("dashboard.company_representatives.phoneNumber"),
            representativeNotContacted: t("dashboard.company_representatives.representativeContactDisclaimer"),
            street: t("dashboard.company_representatives.street"),
            taxNumber: t("application_process.company_details.tin"),
            tinPlaceholder: t("application_process.company_details.placeholder.tin"),
            city: t("dashboard.company_representatives.city"),
            country: t("dashboard.company_representatives.country"),
            willingToSign: t("dashboard.company_representatives.willingToSign"),
        },
        formErrors: {
            fieldRequired: t("common.errors.fieldRequired"),
            fieldInvalid: t("common.errors.fieldInvalid"),
            fieldMustBeBetweenMinMaxNumbers: (min, max) => t("common.errors.fieldMustBeBetweenMinMaxNumbers", { min, max }),
            fieldMustBeBetweenMinMaxChars: (min, max) => t("common.errors.fieldMustBeBetweenMinMaxChars", { min, max }),
            fieldMustBeBetweenMinMax: (min, max) => t("common.errors.fieldMustBeBetweenMinMax", { min, max }),
            fieldMustBeMinChars: (min) => t("common.errors.fieldMustBeMinChars", { min }),
            passwordShort: t("common.errors.passwordTooShort"),
            passwordNotComplex: t("common.errors.passwordNotComplex"),
            invalidPhoneNumber: t("common.errors.invalidPhoneNumber"),
            fieldContainsInvalidCharacters: t("common.errors.fieldContainsInvalidCharacters"),
            personalTinMustBeDifferentThanCompanyTin: t("common.errors.personalTinMustBeDifferentThanCompanyTin"),
        },
        object: {
            object: t("dashboard.objects.object"),
            manufacturer: t("dashboard.objects.manufacturer"),
            type: t("dashboard.objects.type"),
            yearBuilt: t("dashboard.objects.yearBuilt"),
            objectDescription: t("dashboard.objects.detailedDesc"),
            netPurchasePrice: t("dashboard.objects.netPurchasePrice"),
            downPayment: t("dashboard.objects.downPayment"),
            remainingValue: t("dashboard.objects.remainingValue"),
            numberOfObjects: t("dashboard.objects.numberOfObjects"),
            financingType: t("dashboard.objects.financingType"),
            rentalState: t("dashboard.objects.rentalObject"),
            isFixedInterest: t("dashboard.objects.fixedInterest"),
            companyName: t("application_process.company_details.companyName"),
            street: t("dashboard.company_representatives.street"),
            houseNumber: t("dashboard.company_representatives.houseNumber"),
            city: t("dashboard.company_representatives.city"),
            postcode: t("dashboard.company_representatives.postcode"),
            country: t("dashboard.company_representatives.country"),
            retailerCompanyId: t("dashboard.objects.retailerCompanyId"),
            retailerName: t("dashboard.objects.retailerCompanyName"),
            retailerAddress: t("dashboard.objects.retailerAddress"),
            expectedDeliveryDate: t("dashboard.objects.expectedDeliveryDate"),
            createOrderForm: "",
            errors: {
                required: t("common.required"),
                fieldMinLength5: t("common.fieldMinLength", { min: 5 }),
                postcodeInvalid: t("common.errors.postcodeInvalid"),
            },
            placeholders: {
                manufacturer: t("dashboard.objects.placeholder.manufacturer"),
                type: t("dashboard.objects.placeholder.type"),
                yearBuilt: t("dashboard.objects.placeholder.yearBuilt"),
                objectDescription: t("dashboard.objects.placeholder.objectDescription"),
                objectCompanyName: t("dashboard.objects.placeholder.objectCompanyName"),
                street: t("application_process.company_details.placeholder.street"),
                houseNumber: t("application_process.company_details.placeholder.houseNumber"),
                city: t("application_process.company_details.placeholder.city"),
                retailerCompanyId: t("dashboard.objects.placeholder.retailerCompanyId"),
            },
            options: {
                isFixedInterest: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                },
                financingTypes: {
                    leasing: t("common.loan_purpose.leasing"),
                    hire_purchase: t("common.loan_purpose.hire_purchase"),
                    financing: t("common.loan_purpose.financing"),
                    sale_leaseback: t("common.loan_purpose.sale_leaseback"),
                    credit_line: t("common.loan_purpose.credit_line"),
                    other: t("common.loan_purpose.other"),
                },
                rentalStates: {
                    no: t("dashboard.objects.no"),
                    internal: t("dashboard.objects.internal"),
                    b2c: t("dashboard.objects.b2c"),
                },
            },
            sections: {
                retailer: t("dashboard.objects.retailer"),
            },
        },
        companySelectFormItem: {
            label: t("application_process.company_details.companyName"),
            placeholder: t("application_process.company_details.companyNamePlaceholder"),
            errorCompanyNameInvalid: t("common.errors.companyNameInvalid"),
            errorCompanyNameNotFound: t("application_process.company_details.companyNameNotFoundText"),
            errorCompanyNameNotFoundLinkText: t("application_process.company_details.companyNameNotFoundLink"),
        },
        industryCodes: {
            industryCode: t("company.industry_code"),
            industryCodes: t("company.industry_codes"),
            description: t("company.description"),
            weight: t("company.weight"),
            lastUpdatedOn: (date) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.last_updated_on" }, { children: { date } }))),
            source: (source) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.source" }, { children: { source } }))),
            codeType: (type) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.code_type" }, { children: { type } }))),
        },
        industryCodesEditModal: {
            title: t("industry_codes.title"),
            update: t("industry_codes.update"),
            type: t("industry_codes.type"),
            codeEnum: {
                wz_2008: t("industry_codes.wz_2008"),
                noga_2008: t("industry_codes.noga_2008"),
            },
            industryCodeNumber: (index) => t("industry_codes.industry_code", { index }),
            code: t("industry_codes.code"),
            weight: t("industry_codes.weight"),
            optional: t("industry_codes.optional"),
            description: t("industry_codes.description"),
            addIndustryCode: t("industry_codes.add_code"),
            fieldRequired: t("industry_codes.field-required"),
        },
    }), [t, i18n.language]);
    return _jsx(TranslationsProviderUI, Object.assign({ translations: translationsUI }, { children: children }));
};
