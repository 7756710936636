import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import Preview from "@ui/preview/Preview";
import { RoutePaths } from "src/Routes";
const PreviewHeader = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const CompanyPreview = ({ company, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lineData = useMemo(() => {
        if (!company)
            return [];
        return [
            {
                label: t("companies.preview.address"),
                value: [
                    company.company_street,
                    company.company_house_number,
                    company.company_postcode,
                    company.company_location,
                    t(`common.countries.${company.company_country}`),
                ]
                    .filter(Boolean)
                    .join(", "),
            },
            {
                label: t("companies.preview.hr-number"),
                value: company.hr_number ? company.hr_number : "-",
            },
            {
                label: t("companies.preview.founded"),
                value: company.foundation_date ? dayjs(company.foundation_date).format(dayMonthYear) : "-",
            },
            {
                label: t("companies.preview.created"),
                value: company.created_at ? dayjs(company.created_at).format(dayMonthYear) : "-",
            },
        ];
    }, [company, t]);
    return (_jsx(Preview, { entityId: (company === null || company === void 0 ? void 0 : company.company_id) || "", visible: !!company, header: company && (_jsx(PreviewHeader, { children: _jsxs(Row, Object.assign({ style: { width: "100%" }, align: "middle", justify: "space-between" }, { children: [_jsx(Col, { children: _jsx(Typography.Title, Object.assign({ level: 5, style: { margin: 0 } }, { children: company.company_name })) }), _jsx(Col, { children: _jsxs(Space, { children: [_jsx(Button, Object.assign({ type: "primary", onClick: () => navigate(generatePath(RoutePaths.CompanyPortfolioOverview, {
                                        companyId: company.partner_portfolio_item_id,
                                    })) }, { children: t("common.view") })), _jsx(Button, { onClick: onClose, icon: _jsx(CloseOutlined, {}) })] }) })] })) })), lineData: lineData }));
};
export default CompanyPreview;
