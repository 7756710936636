import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import styled from "styled-components";
import { CompanySearchSelectedCompany } from "./company-search-selected-company/CompanySearchSelectedCompany";
const { Title, Text } = Typography;
const NotSelectedContent = styled.div `
	margin: 0 24px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;
export const CompanySearchInfo = ({ selectedCompany }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: selectedCompany ? (_jsx(CompanySearchSelectedCompany, { company: selectedCompany })) : (_jsxs(NotSelectedContent, { children: [_jsx(SearchOutlined, { style: { fontSize: 50, color: "#D9D9D9", margin: "50px 0 24px" } }), _jsx(Title, Object.assign({ level: 5 }, { children: t("company.info.search-for") })), _jsx(Text, Object.assign({ type: "secondary" }, { children: t("company.info.start-typing") }))] })) }));
};
