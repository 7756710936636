import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { generate } from "@ant-design/colors";
import { BankOutlined, ContainerOutlined, LeftOutlined, LogoutOutlined, RightOutlined, SettingOutlined, ShopOutlined, } from "@ant-design/icons";
import { Button, Layout, Space, Tooltip, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import styled, { useTheme } from "styled-components";
import { EntitlementType } from "@teylor-tools/Api";
import { RoutePaths } from "src/Routes";
import { logout } from "src/store/actions/userActions";
import { Axios } from "src/utils/Axios";
const StyledContent = styled(Content) `
	height: 100vh;
	overflow: auto;
`;
const Wrapper = styled.div `
	padding: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100vh;
`;
const WrappedLayout = styled(Layout) `
	height: 100vh;
	display: flex;
	flex-direction: row;
`;
const MenuWrapper = styled.div `
	height: 100%;
	padding-top: 32px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const MenuIconWrapper = styled.div `
	margin-top: ${({ $extended }) => ($extended ? "2" : "8")}px;
	${({ $extended }) => !$extended &&
    `
        transform: scale(1.25);
        margin-left: 9px;
    `}
`;
const Menu = styled(Sider) `
	height: 100vh;
	background: ${(props) => props.theme.main_menu_bg_color} !important;
	padding: 32px ${({ $extended }) => ($extended ? "24" : "8")}px;
`;
const MenuItem = styled(Space) `
	margin: 8px 0;
	transition: opacity 0.3s linear;
	opacity: ${({ $active }) => ($active ? 0.9 : 0.5)};

	&:hover {
		opacity: ${({ $active }) => ($active ? 0.9 : 0.7)};
	}

	color: ${(props) => props.theme.main_menu_color};
`;
const MenuItemText = styled(Typography.Text) `
	color: ${(props) => props.theme.main_menu_color};
`;
const Footer = styled.div `
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;
const FooterButton = styled(Button) `
	padding-left: 0;
	color: ${(props) => props.theme.main_menu_color && generate(props.theme.main_menu_color)[7]};

	&:hover {
		color: ${(props) => props.theme.main_menu_color && generate(props.theme.main_menu_color)[6]} !important;
	}

	&:active {
		color: ${(props) => props.theme.main_menu_color && generate(props.theme.main_menu_color)[8]} !important;
	}
`;
const LogoWrapper = styled.div `
	img {
		max-width: 100%;
	}

	${({ $extended }) => !$extended &&
    `
        margin-left: 4px;
        width: 28px;
    `}
`;
const MenuLayout = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { entitlements } = useSelector((state) => state.configState);
    const showPortfolioPages = (entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) && entitlements.includes(EntitlementType.CompanyResearch);
    const showApplicationsPages = (entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) && entitlements.includes(EntitlementType.PartnerLoanApplications);
    const [extended, setExtended] = useState(localStorage.getItem("sideMenuExtended") === "true");
    const updateExtended = (newExtended) => {
        localStorage.setItem("sideMenuExtended", String(newExtended));
        setExtended(newExtended);
    };
    const handleLogout = () => {
        Axios.post("/partner/account/logout").then(() => {
            dispatch(logout(true));
            navigate(RoutePaths.Login);
        }, (error) => {
            void Axios.error(error, t("common.errors.logout"));
        });
    };
    const renderMenuItems = () => {
        const menuItems = [
            ...(showApplicationsPages
                ? [
                    {
                        name: t("common.label.applications"),
                        link: RoutePaths.Index,
                        icon: _jsx(ContainerOutlined, {}),
                    },
                ]
                : []),
            ...(showPortfolioPages
                ? [
                    {
                        name: t("common.label.company-search"),
                        link: RoutePaths.CompanySearch,
                        icon: _jsx(BankOutlined, {}),
                    },
                    {
                        name: t("common.label.companies"),
                        link: RoutePaths.CompaniesPortfolio,
                        icon: _jsx(ShopOutlined, {}),
                    },
                ]
                : []),
            {
                name: t("common.label.settings"),
                link: RoutePaths.Settings,
                icon: _jsx(SettingOutlined, {}),
            },
        ];
        const footerItems = [
            {
                name: t("common.label.toggle"),
                icon: extended ? _jsx(LeftOutlined, {}) : _jsx(RightOutlined, {}),
                onClick: () => updateExtended(!extended),
            },
            {
                name: t("common.label.signOut"),
                icon: _jsx(LogoutOutlined, {}),
                onClick: handleLogout,
            },
        ];
        return (_jsxs(MenuWrapper, { children: [_jsx(Space, Object.assign({ direction: "vertical", size: 0 }, { children: menuItems.map(({ name, link, icon }, index) => (_jsx(Space, Object.assign({ direction: "vertical" }, { children: _jsx(Link, Object.assign({ to: link && location.pathname !== link ? link : "#" }, { children: _jsxs(MenuItem, Object.assign({ "$active": location.pathname === link, size: 10, "data-cy": "menu-icon" }, { children: [_jsx(Tooltip, Object.assign({ title: name, mouseEnterDelay: 0.5, zIndex: extended ? -1 : undefined }, { children: _jsx(MenuIconWrapper, Object.assign({ "$extended": extended }, { children: icon })) })), _jsx(MenuItemText, { children: extended && name })] })) })) }), `menu-item-${index}`))) })), _jsx(Footer, { children: footerItems.map(({ name, icon, onClick }, index) => (_jsx(Tooltip, Object.assign({ title: name, mouseEnterDelay: 0.5, zIndex: extended ? -1 : undefined }, { children: _jsx(FooterButton, Object.assign({ type: "link", icon: icon, onClick: onClick }, { children: extended && name })) }), `footer-item-${index}`))) })] }));
    };
    return (_jsxs(WrappedLayout, { children: [_jsxs(Menu, Object.assign({ width: extended ? 200 : 50, "$extended": extended }, { children: [theme.logo_web_path && theme.logo_collapsed_web_path && (_jsx(LogoWrapper, Object.assign({ "$extended": extended }, { children: _jsx("img", { src: extended ? theme.logo_web_path : theme.logo_collapsed_web_path, alt: "Logo" }) }))), renderMenuItems()] })), _jsx(StyledContent, { children: _jsx(Wrapper, { children: _jsx(Outlet, {}) }) })] }));
};
export default MenuLayout;
