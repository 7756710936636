import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle `
    // style tooltips in a blue manner
    .global-tooltip-overlay-style {
        &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, 
        &.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, 
        &.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow 
        {
            border-right-color: rgb(38, 172, 225);
            border-bottom-color: rgb(38, 172, 225);
        }

        .ant-popover-inner {
            background: rgb(38, 172, 225);

            .ant-popover-inner-content {
                color: #fff;
            }
        }
    }
`;
export default GlobalStyle;
